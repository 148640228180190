import React, {
  FocusEvent,
  ReactChild,
  useCallback,
  useState,
  VoidFunctionComponent,
} from "react";
import {classNames, isNullOrUndefined} from "../../../utils";
import {Tooltip} from "../../tooltip";
import styles from "./with-label-and-error.module.scss";

export type WithLabelAndErrorProps = {
  className?: string;
  disabled?: boolean;
  error?: ReactChild | null;
  inline?: boolean;
  label: string;
  labelRight?: boolean;
  onBlur?: (event: FocusEvent<HTMLElement>) => void;
  onFocus?: (event: FocusEvent<HTMLElement>) => void;
};

export const withLabelAndError = <BaseComponentProps extends {}>(
  BaseComponent: VoidFunctionComponent<BaseComponentProps>
): VoidFunctionComponent<BaseComponentProps & WithLabelAndErrorProps> => (
  props
) => {
  const {
    className,
    disabled,
    error,
    inline = false,
    label,
    labelRight = false,
    onBlur,
    onFocus,
    ...otherProps
  } = props;
  const hasError = !isNullOrUndefined(error);
  const [visible, setVisible] = useState(false);
  const classes = classNames(styles.root, className, {
    [styles.disabled]: disabled,
    [styles.error]: hasError,
    [styles.inline]: inline,
    [styles.labelRight]: labelRight,
    [styles.visible]: visible,
  });
  const onBlurProxy = useCallback(
    (event: FocusEvent<HTMLElement>) => {
      setVisible(false);
      return onBlur?.(event);
    },
    [onBlur]
  );
  const onFocusProxy = useCallback(
    (event: FocusEvent<HTMLElement>) => {
      setVisible(true);
      return onFocus?.(event);
    },
    [onFocus]
  );
  const labelElement = <div className={styles.label}>{label}</div>;

  return (
    <label className={classes}>
      {!labelRight && labelElement}
      <BaseComponent
        {...((otherProps as unknown) as BaseComponentProps)}
        className={styles.baseComponent}
        disabled={disabled}
        onBlur={onBlurProxy}
        onFocus={onFocusProxy}
      />
      {labelRight && labelElement}
      {hasError && (
        <Tooltip
          className={styles.errorMessage}
          position="bottom"
          visible={hasError}
        >
          {error}
        </Tooltip>
      )}
    </label>
  );
};
