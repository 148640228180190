import React from "react";
import {IDSIconAgent} from "@inera/ids-react";
import {Translation} from "../../../../../services/i18n";
import styles from "../appointments-new-route.module.scss";
import {SlotTypeItem} from "../slottype-item";

type SelectedSlotTypeProps = {
  slotType: SlotTypeItem;
};

export const SelectedSlotType = (props: SelectedSlotTypeProps) => {
  const {slotType} = props;

  return (
    <div className={styles.selectedSlotType}>
      <div className={styles.icon}>
        <IDSIconAgent colorpreset={2} size={"s"} />
      </div>
      <div>
        <div>
          <Translation tKey="book-appointment-selected-slottype" />
        </div>
        <div>
          <strong>{slotType.name}</strong>
        </div>
      </div>
    </div>
  );
};
