import React, {ChangeEvent} from "react";
import {classNames} from "../../../utils";
import styles from "./textarea.module.scss";

export type TextareaProps = {
  autoFocus?: boolean;
  className?: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  rows?: number;
  value?: string;
};

export const Textarea = (props: TextareaProps) => {
  const {className, rows = 10, ...otherProps} = props;
  const classes = classNames(styles.root, className);

  return (
    <textarea
      {...otherProps}
      className={classes}
      style={{height: `${rows + 2}em`}}
    />
  );
};
