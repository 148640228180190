import React, {Component, ErrorInfo, ReactChild} from "react";

type ErrorBoundaryProps = {
  fallback?: ReactChild;
  name: string;
};
type ErrorBoundaryState = {
  hasError: boolean;
};

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError() {
    return {hasError: true};
  }

  componentDidCatch = (error: Error, stack: ErrorInfo) => {
    const {name} = this.props;

    console.error(`ErrorBoundary '${name}' caught an error`, error, stack);
    // TODO: Report to remote error service
  };

  render() {
    const {children, fallback} = this.props;
    const {hasError} = this.state;

    if (hasError) {
      return fallback || <div>Ooops! &#x1F625;</div>;
    }
    return children;
  }
}
