import {TimeUnit} from "./date-time-units";
import {isValidDate} from "./is-date";
import {isFiniteNumber} from "./is-number";

const createModifier = (unit: TimeUnit) => (date: Date, quantity: number) => {
  if (!isValidDate(date)) {
    throw new TypeError("Invalid date");
  }
  if (!isFiniteNumber(quantity)) {
    throw new TypeError("Invalid number");
  }
  return new Date(date.getTime() + quantity * unit);
};

/** Returns a new Date with the number of seconds added. */
export const addSeconds = createModifier(TimeUnit.Second);

/** Returns a new Date with the number of minutes added. */
export const addMinutes = createModifier(TimeUnit.Minute);

/** Returns a new Date with the number of hours added. */
export const addHours = createModifier(TimeUnit.Hour);

/** Returns a new Date with the number of days added. */
export const addDays = createModifier(TimeUnit.Day);

type TimeConfig = {
  hours?: number;
  minutes?: number;
  seconds?: number;
  milliseconds?: number;
};

/** Returns a new date with the time set according to passed config */
export const modifyTime = (
  date: Date,
  {hours, minutes, seconds, milliseconds}: TimeConfig
) => {
  if (!isValidDate(date)) {
    throw new TypeError("Invalid date");
  }
  const msDays = Math.floor(date.getTime() / TimeUnit.Day) * TimeUnit.Day;

  const msHours =
    (isFiniteNumber(hours) ? hours : date.getHours()) * TimeUnit.Hour;

  const msMinutes =
    (isFiniteNumber(minutes) ? minutes : date.getMinutes()) * TimeUnit.Minute;

  const msSeconds =
    (isFiniteNumber(seconds) ? seconds : date.getSeconds()) * TimeUnit.Second;

  const ms = isFiniteNumber(milliseconds)
    ? milliseconds
    : date.getMilliseconds();

  return new Date(msDays + msHours + msMinutes + msSeconds + ms);
};

/** Returns a new date with the time set to 00:00:00.000 */
export const resetTime = (date: Date) =>
  modifyTime(date, {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });
