import {isString} from "./is-string";

export class AssertionError extends Error {
  constructor(message: string) {
    super(message);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AssertionError);
    }
    this.name = "AssertionError";
  }
}

export const assert = (condition: boolean, message = "") => {
  if (!condition) {
    const error = new AssertionError(message);
    const {stack} = error;

    if (isString(stack)) {
      const [name, ...lines] = stack.split("\n");

      // Remove the stack entry from this function
      lines.shift();
      error.stack = [name, ...lines].join("\n");
    }
    throw error;
  }
};
