import React, {ReactNode} from "react";
import {classNames} from "../../utils";
import {Optional} from "../../utils/types";
import styles from "./headings.module.scss";

export type HeadingTag = "h1" | "h2" | "h3" | "h4";

type HeadingBaseProps = {
  children: ReactNode;
  className?: string;
  look: HeadingTag;
  inspiring?: boolean;
  layout?: boolean;
  tag: HeadingTag;
};

const HeadingBase = (props: HeadingBaseProps) => {
  const {
    children,
    className,
    inspiring = false,
    layout = false,
    look,
    tag,
    ...otherProps
  } = props;
  const classes = classNames(className, styles.root, {
    [styles.h1]: look === "h1",
    [styles.h2]: look === "h2",
    [styles.h3]: look === "h3",
    [styles.h4]: look === "h4",
    [styles.inspiring]: inspiring,
    [styles.layout]: layout,
  });

  return React.createElement(
    tag,
    {className: classes, ...otherProps},
    children
  );
};

// Make all props optional, set defaults and allow for overrides
export type HeadingProps = Optional<HeadingBaseProps, "look" | "tag">;

export const H1 = (props: HeadingProps) => (
  <HeadingBase look="h1" tag="h1" {...props} />
);
export const H2 = (props: HeadingProps) => (
  <HeadingBase look="h2" tag="h2" {...props} />
);
export const H3 = (props: HeadingProps) => (
  <HeadingBase look="h3" tag="h3" {...props} />
);
export const H4 = (props: HeadingProps) => (
  <HeadingBase look="h4" tag="h4" {...props} />
);
