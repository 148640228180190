import React from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {NotFound} from "./404";
import {ClinicsRoute} from "./clinics";

export const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/clinics" component={ClinicsRoute} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};
