import {TimeUnit} from "./date-time-units";
import {isValidDate} from "./is-date";

const createInUnit = (unit: TimeUnit) => (date: Date) => {
  if (!isValidDate(date)) {
    throw new TypeError("Invalid date");
  }
  return Math.floor(date.getTime() / unit);
};

/** Returns the number of seconds since epoch */
export const inSeconds = createInUnit(TimeUnit.Second);
/** Returns the number of minutes since epoch */
export const inMinutes = createInUnit(TimeUnit.Minute);
/** Returns the number of hours since epoch */
export const inHours = createInUnit(TimeUnit.Hour);
/** Returns the number of days since epoch */
export const inDays = createInUnit(TimeUnit.Day);

const createIsSame = (unit: TimeUnit) => (a: Date, b: Date) => {
  const getInUnit = createInUnit(unit);

  return getInUnit(a) === getInUnit(b);
};

/** Compares the number of seconds since epoch */
export const isSameSecond = createIsSame(TimeUnit.Second);
/** Compares the number of minutes since epoch */
export const isSameMinute = createIsSame(TimeUnit.Minute);
/** Compares the number of hours since epoch */
export const isSameHour = createIsSame(TimeUnit.Hour);
/** Compares the number of days since epoch */
export const isSameDay = createIsSame(TimeUnit.Day);
