export type Maybe<T> = T | null;
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: string;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: string;
  /** A field whose value conforms to the standard internet email address format as specified in RFC822: https://www.w3.org/Protocols/rfc822/. */
  EmailAddress: string;
  /**
   *
   *     A string representing a duration conforming to the ISO8601 standard,
   *     such as: P1W1DT13H23M34S
   *     P is the duration designator (for period) placed at the start of the duration representation.
   *     Y is the year designator that follows the value for the number of years.
   *     M is the month designator that follows the value for the number of months.
   *     W is the week designator that follows the value for the number of weeks.
   *     D is the day designator that follows the value for the number of days.
   *     T is the time designator that precedes the time components of the representation.
   *     H is the hour designator that follows the value for the number of hours.
   *     M is the minute designator that follows the value for the number of minutes.
   *     S is the second designator that follows the value for the number of seconds.
   *
   *     Note the time designator, T, that precedes the time value.
   *
   *     Matches moment.js, Luxon and DateFns implementations
   *     ,/. is valid for decimal places and +/- is a valid prefix
   *
   */
  ISO8601Duration: string;
  /** A field whose value conforms to the standard E.164 format as specified in: https://en.wikipedia.org/wiki/E.164. Basically this is +17895551234. */
  PhoneNumber: string;
  /** A time string at UTC, such as 10:15:30Z, compliant with the `full-time` format outlined in section 5.6 of the RFC 3339profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Time: string;
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  URL: string;
};

export type Appointment = Node & {
  readonly id: Scalars["ID"];
  readonly version: Scalars["String"];
  readonly status: AppointmentStatus;
  readonly comment: Maybe<Scalars["String"]>;
  readonly description: Maybe<Scalars["String"]>;
  readonly reason: Maybe<Scalars["String"]>;
  readonly start: Maybe<Scalars["DateTime"]>;
  readonly end: Maybe<Scalars["DateTime"]>;
  readonly duration: Maybe<Scalars["ISO8601Duration"]>;
  readonly serviceType: Maybe<Scalars["String"]>;
  readonly requestedPeriod: Maybe<ReadonlyArray<Maybe<Period>>>;
  readonly location: Maybe<Location>;
  readonly patient: Maybe<Patient>;
  readonly practitioners: Maybe<ReadonlyArray<Maybe<Practitioner>>>;
};

export type AppointmentConnection = Connection & {
  readonly edges: ReadonlyArray<AppointmentEdge>;
  readonly pageInfo: PageInfo;
};

export type AppointmentEdge = Edge & {
  readonly cursor: Scalars["String"];
  readonly node: Appointment;
};

export const AppointmentStatus = {
  BOOKED: "BOOKED",
  CANCELLED: "CANCELLED",
  FULFILLED: "FULFILLED",
  NOSHOW: "NOSHOW",
} as const;

export type AppointmentStatus =
  typeof AppointmentStatus[keyof typeof AppointmentStatus];
export type AppointmentWhereInput = {
  readonly id: Scalars["ID"];
};

export type AppointmentsWhereInput = {
  readonly from: Maybe<Scalars["DateTime"]>;
  readonly to: Maybe<Scalars["DateTime"]>;
  readonly duration: Maybe<Scalars["ISO8601Duration"]>;
  readonly status: Maybe<Scalars["String"]>;
};

export type Booking = Node & {
  readonly entity_type: Scalars["String"];
  readonly id: Scalars["ID"];
  readonly version: Maybe<Scalars["String"]>;
  readonly status: BookingStatus;
  readonly clinic: Clinic;
  readonly resident: EntityReference;
  readonly slot: Slot;
};

export type BookingConnection = Connection & {
  readonly edges: ReadonlyArray<BookingEdge>;
  readonly pageInfo: PageInfo;
};

export type BookingEdge = Edge & {
  readonly cursor: Scalars["String"];
  readonly node: Booking;
};

export const BookingStatus = {
  BOOKED: "BOOKED",
  CANCELLED: "CANCELLED",
  FULFILLED: "FULFILLED",
  NOSHOW: "NOSHOW",
} as const;

export type BookingStatus = typeof BookingStatus[keyof typeof BookingStatus];
export type BookingWhereInput = {
  readonly clinicId: Scalars["ID"];
  readonly slotId: Scalars["ID"];
};

export type CancelAppointmentMutationResponse = MutationResponse & {
  readonly code: Scalars["Int"];
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
  readonly appointment: Maybe<Appointment>;
};

export type CancelAppointmentWhereInput = {
  readonly id: Scalars["ID"];
  readonly version: Scalars["String"];
};

export type CancelBookingMutationResponse = {
  readonly booking: Booking;
};

export type CancelBookingWhereInput = {
  readonly clinicId: Scalars["ID"];
  readonly identifier: PerpetualEntityIdentifier;
};

export type CareUnit = Node & {
  readonly id: Scalars["ID"];
  readonly name: Maybe<Scalars["String"]>;
  readonly version: Maybe<Scalars["String"]>;
  /** @deprecated Use `homePage`. */
  readonly url: Maybe<Scalars["String"]>;
  readonly homePage: Maybe<Scalars["URL"]>;
  readonly description: Maybe<Scalars["String"]>;
  readonly phoneNumber: Maybe<Scalars["String"]>;
  readonly postalAddress: Maybe<ReadonlyArray<Maybe<Scalars["String"]>>>;
  readonly visitingAddress: Maybe<ReadonlyArray<Maybe<Scalars["String"]>>>;
  readonly coordinates: Maybe<Coordinates>;
  readonly dropInHours: Maybe<ReadonlyArray<Maybe<Hours>>>;
  readonly phoneHours: Maybe<ReadonlyArray<Maybe<Hours>>>;
  readonly surgeryHours: Maybe<ReadonlyArray<Maybe<Hours>>>;
  readonly visitingHours: Maybe<ReadonlyArray<Maybe<Hours>>>;
};

export type CareUnitConnection = Connection & {
  readonly edges: ReadonlyArray<CareUnitEdge>;
  readonly pageInfo: PageInfo;
};

export type CareUnitEdge = Edge & {
  readonly cursor: Scalars["String"];
  readonly node: CareUnit;
};

export type CareUnitWhereInput = {
  readonly id: Scalars["ID"];
};

export type Clinic = Node & {
  readonly id: Scalars["ID"];
  readonly name: Scalars["String"];
  readonly entity_type: Scalars["String"];
  readonly version: Maybe<Scalars["String"]>;
  readonly hsaId: Scalars["String"];
  readonly address: Maybe<ReadonlyArray<Scalars["String"]>>;
  readonly phone: Maybe<Scalars["String"]>;
  readonly type: ClinicType;
  readonly externalBookingStartRestriction: Scalars["String"];
  readonly enableSelectSlotType: Scalars["Boolean"];
  readonly referralInformation: Maybe<
    ReadonlyArray<ReferralInformationSection>
  >;
};

export type ClinicConnection = Connection & {
  readonly edges: ReadonlyArray<ClinicEdge>;
  readonly pageInfo: PageInfo;
};

export type ClinicEdge = Edge & {
  readonly cursor: Scalars["String"];
  readonly node: Clinic;
};

export const ClinicType = {
  CLINICAL_PHYSIOLOGY: "CLINICAL_PHYSIOLOGY",
  RADIOLOGY: "RADIOLOGY",
  SAMPLE_TAKING: "SAMPLE_TAKING",
} as const;

export type ClinicType = typeof ClinicType[keyof typeof ClinicType];
export type Condition = {
  readonly operator: Maybe<Scalars["String"]>;
  readonly property: Maybe<Scalars["String"]>;
  readonly value: Maybe<Scalars["String"]>;
};

export type Connection = {
  readonly edges: ReadonlyArray<Edge>;
  readonly pageInfo: PageInfo;
};

export type Coordinates = {
  readonly latitude: Maybe<Scalars["Float"]>;
  readonly longitude: Maybe<Scalars["Float"]>;
};

export type Edge = {
  readonly cursor: Scalars["String"];
  readonly node: Node;
};

export type EntityReference = {
  readonly id: Maybe<Scalars["String"]>;
  readonly reference: Maybe<Scalars["String"]>;
};

export type GetBookingWhereInput = {
  readonly clinicId: Scalars["ID"];
  readonly bookingId: Scalars["ID"];
};

export type GetClinicWhereInput = {
  readonly hsaId: Scalars["ID"];
};

export type Hours = {
  readonly startDayOfWeek: Maybe<Scalars["String"]>;
  readonly endDayOfWeek: Maybe<Scalars["String"]>;
  readonly startTime: Maybe<Scalars["String"]>;
  readonly endTime: Maybe<Scalars["String"]>;
  readonly description: Maybe<Scalars["String"]>;
};

export type ListResponse = {
  readonly meta: Maybe<Meta>;
  readonly data: Maybe<ReadonlyArray<Maybe<Scalars["String"]>>>;
};

export type Location = Node & {
  readonly id: Scalars["ID"];
  readonly name: Scalars["String"];
  readonly careUnit: Maybe<CareUnit>;
};

export type LocationWhereInput = {
  readonly id: Scalars["ID"];
};

export type Meta = {
  readonly item_count: Maybe<Scalars["Int"]>;
  readonly first_id: Maybe<Scalars["String"]>;
  readonly last_id: Maybe<Scalars["String"]>;
};

export type Mutation = {
  readonly _noop: Maybe<MutationResponse>;
  readonly cancelAppointment: CancelAppointmentMutationResponse;
  readonly addBooking: Maybe<Booking>;
  readonly cancelBooking: CancelBookingMutationResponse;
};

export type MutationCancelAppointmentArgs = {
  where: CancelAppointmentWhereInput;
};

export type MutationAddBookingArgs = {
  where: BookingWhereInput;
};

export type MutationCancelBookingArgs = {
  where: CancelBookingWhereInput;
};

export type MutationResponse = {
  readonly code: Scalars["Int"];
  readonly success: Scalars["Boolean"];
  readonly message: Scalars["String"];
};

export type Node = {
  readonly id: Scalars["ID"];
};

export type PageInfo = {
  readonly hasPreviousPage: Maybe<Scalars["Boolean"]>;
  readonly hasNextPage: Maybe<Scalars["Boolean"]>;
  readonly startCursor: Maybe<Scalars["String"]>;
  readonly endCursor: Maybe<Scalars["String"]>;
};

export type Patient = Node & {
  readonly id: Scalars["ID"];
  readonly name: Scalars["String"];
};

export type PatientWhereInput = {
  readonly id: Scalars["ID"];
};

export type Period = {
  readonly start: Maybe<Scalars["String"]>;
  readonly end: Maybe<Scalars["String"]>;
};

export type PerpetualEntityIdentifier = {
  readonly id: Scalars["String"];
  readonly version: Scalars["String"];
};

export type Practitioner = Node & {
  readonly id: Scalars["ID"];
  readonly name: Scalars["String"];
};

export type PractitionerWhereInput = {
  readonly id: Scalars["ID"];
};

export type Query = {
  readonly node: Maybe<Node>;
  readonly appointments: AppointmentConnection;
  readonly appointment: Maybe<Appointment>;
  readonly searchBookings: BookingConnection;
  readonly booking: Booking;
  readonly careListings: CareUnitConnection;
  readonly careUnits: CareUnitConnection;
  readonly careUnit: Maybe<CareUnit>;
  readonly clinics: ClinicConnection;
  readonly clinic: Maybe<Clinic>;
  readonly location: Maybe<Location>;
  readonly patient: Maybe<Patient>;
  readonly practitioner: Maybe<Practitioner>;
  readonly searchSlots: SlotConnection;
};

export type QueryNodeArgs = {
  id: Scalars["ID"];
};

export type QueryAppointmentsArgs = {
  where: Maybe<AppointmentsWhereInput>;
  after: Maybe<Scalars["String"]>;
  before: Maybe<Scalars["String"]>;
  first: Maybe<Scalars["Int"]>;
  last: Maybe<Scalars["Int"]>;
};

export type QueryAppointmentArgs = {
  where: AppointmentWhereInput;
};

export type QuerySearchBookingsArgs = {
  where: SearchBookingsWhereInput;
  after: Maybe<Scalars["String"]>;
  before: Maybe<Scalars["String"]>;
  first: Maybe<Scalars["Int"]>;
  last: Maybe<Scalars["Int"]>;
};

export type QueryBookingArgs = {
  where: GetBookingWhereInput;
};

export type QueryCareListingsArgs = {
  after: Maybe<Scalars["String"]>;
  before: Maybe<Scalars["String"]>;
  first: Maybe<Scalars["Int"]>;
  last: Maybe<Scalars["Int"]>;
};

export type QueryCareUnitsArgs = {
  after: Maybe<Scalars["String"]>;
  before: Maybe<Scalars["String"]>;
  first: Maybe<Scalars["Int"]>;
  last: Maybe<Scalars["Int"]>;
};

export type QueryCareUnitArgs = {
  where: CareUnitWhereInput;
};

export type QueryClinicsArgs = {
  after: Maybe<Scalars["String"]>;
  before: Maybe<Scalars["String"]>;
  first: Maybe<Scalars["Int"]>;
  last: Maybe<Scalars["Int"]>;
};

export type QueryClinicArgs = {
  where: GetClinicWhereInput;
};

export type QueryLocationArgs = {
  where: LocationWhereInput;
};

export type QueryPatientArgs = {
  where: PatientWhereInput;
};

export type QueryPractitionerArgs = {
  where: PractitionerWhereInput;
};

export type QuerySearchSlotsArgs = {
  where: SearchSlotsWhereInput;
  after: Maybe<Scalars["String"]>;
  before: Maybe<Scalars["String"]>;
  first: Maybe<Scalars["Int"]>;
  last: Maybe<Scalars["Int"]>;
};

export type ReferralInformationSection = {
  readonly title: Maybe<Scalars["String"]>;
  readonly lines: Maybe<ReadonlyArray<Scalars["String"]>>;
};

export type SearchBookingsWhereInput = {
  readonly clinicId: Scalars["ID"];
  readonly filter: SearchFilter;
};

export type SearchFilter = {
  readonly operator: Scalars["String"];
  readonly conditions: Maybe<ReadonlyArray<Maybe<Condition>>>;
};

export type SearchSlotsWhereInput = {
  readonly clinicId: Scalars["ID"];
  readonly filter: Maybe<SearchFilter>;
};

export type Slot = Node & {
  readonly clinic: EntityReference;
  readonly start: Scalars["DateTime"];
  readonly end: Scalars["DateTime"];
  readonly slot_type: SlotType;
  readonly slot_type_id: Scalars["ID"];
  readonly appointment: Maybe<EntityReference>;
  readonly resource_index: Scalars["Int"];
  readonly id: Scalars["ID"];
  readonly version: Maybe<Scalars["String"]>;
  readonly entity_type: Scalars["String"];
};

export type SlotType = Node & {
  readonly name: Scalars["String"];
  readonly visibility: Scalars["Int"];
  readonly id: Scalars["ID"];
};

export type SlotConnection = Connection & {
  readonly edges: ReadonlyArray<SlotEdge>;
  readonly pageInfo: PageInfo;
};

export type SlotEdge = Edge & {
  readonly cursor: Scalars["String"];
  readonly node: Slot;
};

export const SlotStatus = {
  CREATED: "CREATED",
  BLOCKED: "BLOCKED",
} as const;

export type SlotStatus = typeof SlotStatus[keyof typeof SlotStatus];

export type MultipleSlotTypes = typeof SlotStatus[keyof typeof SlotStatus];
