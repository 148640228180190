import {gql} from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../../../types/schema";
const defaultOptions = {};
export type CancelBookingMutationVariables = Types.Exact<{
  clinicId: Types.Scalars["ID"];
  id: Types.Scalars["String"];
  version: Types.Scalars["String"];
}>;

export type CancelBookingMutation = {
  readonly cancelBooking: {
    readonly booking: Pick<Types.Booking, "id" | "status" | "version"> & {
      readonly slot: Pick<Types.Slot, "id" | "start" | "end">;
      readonly clinic: Pick<Types.Clinic, "id" | "name" | "address" | "phone">;
    };
  };
};

export const CancelBookingDocument = gql`
  mutation CancelBooking($clinicId: ID!, $id: String!, $version: String!) {
    cancelBooking(
      where: {clinicId: $clinicId, identifier: {id: $id, version: $version}}
    ) {
      booking {
        id
        slot {
          id
          start
          end
        }
        clinic {
          id
          name
          address
          phone
        }
        status
        version
      }
    }
  }
`;
export type CancelBookingMutationFn = Apollo.MutationFunction<
  CancelBookingMutation,
  CancelBookingMutationVariables
>;
export function useCancelBookingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelBookingMutation,
    CancelBookingMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    CancelBookingMutation,
    CancelBookingMutationVariables
  >(CancelBookingDocument, options);
}
export type CancelBookingMutationHookResult = ReturnType<
  typeof useCancelBookingMutation
>;
export type CancelBookingMutationResult =
  Apollo.MutationResult<CancelBookingMutation>;
export type CancelBookingMutationOptions = Apollo.BaseMutationOptions<
  CancelBookingMutation,
  CancelBookingMutationVariables
>;
