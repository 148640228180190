import React, {FunctionComponent, useRef} from "react";
import {ModalContext} from "./modal-context";

const {Provider} = ModalContext;

export const ModalProvider: FunctionComponent = ({children}) => {
  const rootRef = useRef<HTMLDivElement>(null);

  return (
    <Provider value={{rootRef}}>
      {children}
      <div id="modal-outlet" ref={rootRef} />
    </Provider>
  );
};
