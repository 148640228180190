import {gql} from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../../../types/schema";
const defaultOptions = {};
export type SlotTypeQueryVariables = Types.Exact<{
  clinicId: Types.Scalars["ID"];
  filter: Types.SearchFilter;
}>;

export type SlotTypesQuery = {
  readonly searchSlotTypes: {
    readonly edges: ReadonlyArray<{
      readonly node: Pick<Types.SlotType, "id" | "name" | "visibility">;
    }>;
  };
};

export const SlotTypesDocument = gql`
  query SlotTypes($clinicId: ID!, $filter: SearchFilter!) {
    searchSlotTypes(where: {clinicId: $clinicId, filter: $filter}) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;
export function useSlotTypesQuery(
  baseOptions: Apollo.QueryHookOptions<SlotTypesQuery, SlotTypeQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<SlotTypesQuery, SlotTypeQueryVariables>(
    SlotTypesDocument,
    options
  );
}
export function useSlotTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SlotTypesQuery,
    SlotTypeQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<SlotTypesQuery, SlotTypeQueryVariables>(
    SlotTypesDocument,
    options
  );
}
export type SlotTypesQueryHookResult = ReturnType<typeof useSlotTypesQuery>;
export type SlotTypesQueryLazyQueryHookResult = ReturnType<
  typeof useSlotTypesLazyQuery
>;
export type SlotTypesQueryResult = Apollo.QueryResult<
  SlotTypesQuery,
  SlotTypeQueryVariables
>;
