import React, {ReactNode} from "react";
import {classNames} from "../../utils";
import {ErrorBoundary} from "../error-boundary";
import {Footer} from "../footer";
import {Header} from "../header";
import styles from "./default-layout.module.scss";

export type DefaultLayoutProps = {
  children: ReactNode;
  className?: string;
};

export const DefaultLayout = (props: DefaultLayoutProps) => {
  const {children, className} = props;

  return (
    <div className={styles.root}>
      <header className={styles.header}>
        <Header className={styles.content} />
      </header>
      <main className={styles.main}>
        <div className={classNames(styles.content, className)}>
          {/* TODO: Add ErrorBoundary fallback */}
          <ErrorBoundary name="DefaultLayoutContent">{children}</ErrorBoundary>
        </div>
      </main>
      <footer className={styles.footer}>
        <Footer className={styles.content} />
        <div className={styles.bottom} />
      </footer>
    </div>
  );
};
