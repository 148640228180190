import {useEffect, useState} from "react";
import {isError} from "../../utils";

export type Session = {
  name: string;
};

export const useSessionQuery = () => {
  // Replace with React Query?
  const [data, setData] = useState<Session | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch("/auth/saml/session");
        const body = await response.json();

        setData(body as Session);
      } catch (error: unknown) {
        if (isError(error)) {
          setError(error);
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return {
    data,
    error,
    isError: error !== null,
    isLoading,
  };
};
