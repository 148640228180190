import {
  isFiniteNumber,
  isNonEmptyObject,
  isNonEmptyString,
  isObject,
  isString,
} from "./";

export const joinPaths = (...segments: string[]) =>
  segments
    .join("/")
    // Replace any double slashes with a single one,
    // but disregard protocol like '://' pattern
    .replace(/(^|[^:])\/{2,}/g, "$1/")
    // Remove any trailing slash
    .replace(/\/$/, "");

export const routeWithId =
  (base: string, collection: string) => (id?: null | string) => {
    const route = joinPaths(base, collection);

    if (isNonEmptyString(id)) {
      return joinPaths(route, id);
    }
    return route;
  };

export const routeWithIdAndAction = <Action extends string>(
  base: string,
  collection: string
) => {
  const getRoute = routeWithId(base, collection);

  return (id?: null | string, action?: Action) => {
    const route = getRoute(id);

    if (isNonEmptyString(action)) {
      return joinPaths(route, action);
    }
    return route;
  };
};

export type QueryObject = Record<string, unknown>;
export type SearchParamsSerializable = Record<string, string>;

export const routeWithIdActionAndQuery = <
  Action extends string,
  Query extends QueryObject
>(
  base: string,
  collection: string
) => {
  const getRoute = routeWithIdAndAction(base, collection);

  return (id?: null | string, action?: Action, query?: Query) => {
    const route = getRoute(id, action);
    const serializable = toSearchParamsSerializable(query);

    if (isNonEmptyObject(serializable)) {
      return `${route}?${new URLSearchParams(serializable).toString()}`;
    }
    return route;
  };
};

function toSearchParamsSerializable(
  object?: Record<string, unknown>
): SearchParamsSerializable {
  if (!isObject(object)) {
    return {};
  }
  return Object.entries(object).reduce<SearchParamsSerializable>(
    (accumulator, entry) => {
      const [key, value] = entry;

      if (isFiniteNumber(value)) {
        accumulator[key] = String(value);
      }
      if (isString(value)) {
        accumulator[key] = value;
      }
      return accumulator;
    },
    {}
  );
}
