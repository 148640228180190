import React from "react";
import {H1} from "../../components/headings";
import {DefaultLayout} from "../../components/layouts/default-layout";
import styles from "./not-found.module.scss";

export const NotFound = () => (
  <DefaultLayout className={styles.root}>
    <H1>&#x1F625;</H1>
    <p>Sidan du söker finns tyvärr inte.</p>
  </DefaultLayout>
);
