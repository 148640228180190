import {useMemo} from "react";
import {LocationDescriptor} from "history";
import {useHistory, useParams, useRouteMatch} from "react-router-dom";
import {toQueryStringObject} from "../../utils";

export const useRouting = <Params extends object>() => {
  const history = useHistory();
  const match = useRouteMatch();
  const params = useParams<Params>();

  return useMemo(() => {
    const back = () => history.goBack();
    const forward = () => history.goForward();
    const location = history.location;
    const query = toQueryStringObject(history.location.search);
    const redirect = (location: LocationDescriptor | string) =>
      history.push(location);

    return {
      back,
      forward,
      location,
      match,
      params,
      query,
      redirect,
    };
  }, [history, match, params]);
};
