import React, {FunctionComponent, useEffect} from "react";
import FocusTrap from "focus-trap-react";
import {useTranslation} from "../../services/i18n";
import {classNames} from "../../utils";
import {EVENT_KEYDOWN, KEY_ESCAPE} from "../../utils/dom";
import {Button} from "../forms";
import styles from "./dialog.module.scss";

type DialogProps = {
  cancelButtonLabel?: string;
  className?: string;
  okButtonLabel?: string;
  onCancel: () => void;
  onOk: () => void;
  show?: boolean;
  title?: string;
};

export const Dialog: FunctionComponent<DialogProps> = (props) => {
  const tDialogConfirmTitle = useTranslation("confirm-dialog-title");
  const tCancelButton = useTranslation("cancel-button");
  const tOkButton = useTranslation("ok-button");
  const {
    cancelButtonLabel = tCancelButton,
    children,
    className,
    okButtonLabel = tOkButton,
    onCancel,
    onOk,
    show,
    title = tDialogConfirmTitle,
  } = props;
  const classes = classNames(styles.root, className);

  useEffect(() => {
    const onDocumentKeyDown = (event: KeyboardEvent) => {
      if (event.key === KEY_ESCAPE) {
        onCancel();
      }
    };

    document.addEventListener(EVENT_KEYDOWN, onDocumentKeyDown);
    return () => {
      document.removeEventListener(EVENT_KEYDOWN, onDocumentKeyDown);
    };
  }, [onCancel]);

  if (!show) {
    return null;
  }
  return (
    <FocusTrap>
      <div
        aria-describedby="modal-dialog"
        aria-labelledby="modal"
        aria-modal="true"
        className={classes}
        id="dialog-modal"
        role="dialog"
      >
        <h3 className={styles.headline}>{title}</h3>
        <div className={styles.description}>
          <p>{children}</p>
        </div>

        <div className={styles.buttonGroup}>
          <Button autoFocus onClick={() => onCancel()}>
            {cancelButtonLabel}
          </Button>
          <Button onClick={() => onOk()} primary>
            {okButtonLabel}
          </Button>
        </div>
      </div>
    </FocusTrap>
  );
};
