import React from "react";
import {IDSIconCalendar} from "@inera/ids-react";
import {
  toLongDateString,
  toTimeString,
  Translation,
  useLocale,
} from "../../../../../services/i18n";
import styles from "../appointments-new-route.module.scss";
import {SlotItem} from "../slot-item";

type SelectedSlotProps = {
  slot: SlotItem;
};

export const SelectedSlot = (props: SelectedSlotProps) => {
  const {slot} = props;
  const {currentLocale} = useLocale();

  return (
    <div className={styles.selectedSlot}>
      <div className={styles.icon}>
        <IDSIconCalendar size={"s"} colorpreset={2} />
      </div>
      <div>
        <div>
          <Translation tKey="book-appointment-selected-slot" />
        </div>
        <div>
          <strong>
            {toTimeString(slot.start, currentLocale.tag)}
            {", "}
            {toLongDateString(slot.start, currentLocale.tag)}
          </strong>
        </div>
      </div>
    </div>
  );
};
