/*
 * [!] SlotTypeVisibility uses bitwise flags and masks.
 */

//    Visibility           order   DECIMAL   BINARY
const NONE = 0; //     0       0000
const INTERNAL_CLINIC = 1 << 0; //     1       0001
const RESIDENT_PORTAL = 1 << 1; //     2       0010
const EXTERNAL_CLINIC = 1 << 2; //     4       0100

export const SlotTypeVisibility = {
  NONE,
  INTERNAL_CLINIC,
  RESIDENT_PORTAL,
  EXTERNAL_CLINIC,
} as const;
