import {IDSRadio, IDSRadioGroup} from "@inera/ids-react";
import {Translation} from "../../../../../services/i18n";
import {SlotType} from "../../../../../types/schema";
import styles from "../appointments-new-route.module.scss";

type SelectSlotTypeProps = {
  onSlotTypeChange: (value: SlotType) => void;
  selectedSlotTypeRef: React.MutableRefObject<SlotType | undefined>;
  slotTypes: SlotType[];
};

export const SelectSlotType = (props: SelectSlotTypeProps) => {
  const {selectedSlotTypeRef, onSlotTypeChange, slotTypes} = props;

  const handleSlotTypeChange = (newSlotType: SlotType) => {
    onSlotTypeChange(newSlotType);
    if (selectedSlotTypeRef) {
      selectedSlotTypeRef.current = newSlotType;
    }
  };

  const radioGroupId = "select-type-of-slot";

  return (
    <div>
      <div
        aria-labelledby={radioGroupId}
        className={styles.content}
        role="radiogroup"
      >
        <h2 className={styles.selectSlotType} id={radioGroupId}>
          <Translation tKey="book-appointment-step-1-title" />
        </h2>
        <div className={styles.slotType}>
          <IDSRadioGroup legend="" name="options">
            {slotTypes.map((slotType) => (
              <IDSRadio key={slotType.id}>
                <input
                  type="radio"
                  value={slotType.name}
                  checked={selectedSlotTypeRef.current?.name === slotType.name}
                  onChange={() => handleSlotTypeChange(slotType)}
                />
                <label className={styles.slotType}>{slotType.name}</label>
              </IDSRadio>
            ))}
          </IDSRadioGroup>
        </div>
      </div>
    </div>
  );
};
