import {ChangeEvent, ReactChild, VoidFunctionComponent} from "react";
import {isNullOrUndefined, classNames, without, unique} from "../../../utils";
import {Tooltip} from "../../tooltip";
import {Option} from "../option";
import styles from "./with-group.module.scss";

interface WithGroupProps {
  className?: string;
  disabled?: boolean;
  error?: ReactChild | null;
  inline?: boolean;
  label?: string;
  labelRight?: boolean;
  name: string;
  onValuesChange: (values: string[]) => void;
  options: Option[];
  values: string[];
}

export const withGroup =
  <BaseComponentProps extends {}>(
    BaseComponent: VoidFunctionComponent<BaseComponentProps>
  ): VoidFunctionComponent<
    Omit<BaseComponentProps, "checked" | "label" | "value"> & WithGroupProps
  > =>
  (props) => {
    const {
      className,
      disabled,
      error,
      inline,
      label,
      labelRight,
      name,
      onValuesChange,
      options,
      values,
      ...otherProps
    } = props;
    const classes = classNames(styles.root, className, {
      [styles.inline]: inline,
    });

    return (
      <div className={classes}>
        {label && <div className={styles.label}>{label}</div>}
        <div className={styles.group}>
          {options.map((option) => {
            const checked = values.includes(option.value);

            return (
              <BaseComponent
                {...(otherProps as unknown as BaseComponentProps)}
                checked={checked}
                className={styles.item}
                disabled={disabled}
                inline={true}
                key={option.label + option.value}
                label={option.label}
                labelRight={labelRight}
                name={name}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  const {type, value} = event.target;

                  if (type === "checkbox") {
                    onValuesChange(toggleArrayItem(value, values));
                  } else if (type === "radio") {
                    onValuesChange([value]);
                  } else {
                    throw new Error("Unknown type");
                  }
                }}
                value={option.value}
              />
            );
          })}
        </div>
        <Tooltip visible={!isNullOrUndefined(error)}>{error}</Tooltip>
      </div>
    );
  };

function toggleArrayItem<T extends unknown>(value: T, values: T[]) {
  const uniqueValues = unique(values);

  if (uniqueValues.includes(value)) {
    return without(value, uniqueValues);
  }
  return [...uniqueValues, value];
}
