import {gql} from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../../../types/schema";
const defaultOptions = {};
export type AppointmentsListRouteQueryVariables = Types.Exact<{
  clinicId: Types.Scalars["ID"];
  filter: Types.SearchFilter;
}>;

export type AppointmentsListRouteQuery = {
  readonly searchBookings: {
    readonly edges: ReadonlyArray<{
      readonly node: Pick<Types.Booking, "id" | "status" | "version"> & {
        readonly slot: Pick<Types.Slot, "id" | "start" | "end"> & {
          readonly slot_type: Pick<Types.SlotType, "id" | "name">;
        };
        readonly clinic: Pick<
          Types.Clinic,
          "id" | "name" | "address" | "phone"
        >;
      };
    }>;
  };
};

export const AppointmentsListRouteDocument = gql`
  query AppointmentsListRoute($clinicId: ID!, $filter: SearchFilter!) {
    searchBookings(where: {clinicId: $clinicId, filter: $filter}) {
      edges {
        node {
          id
          slot {
            id
            start
            end
            slot_type {
              id
              name
            }
          }
          clinic {
            id
            name
            address
            phone
          }
          status
          version
        }
      }
    }
  }
`;
export function useAppointmentsListRouteQuery(
  baseOptions: Apollo.QueryHookOptions<
    AppointmentsListRouteQuery,
    AppointmentsListRouteQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    AppointmentsListRouteQuery,
    AppointmentsListRouteQueryVariables
  >(AppointmentsListRouteDocument, options);
}
export function useAppointmentsListRouteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppointmentsListRouteQuery,
    AppointmentsListRouteQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    AppointmentsListRouteQuery,
    AppointmentsListRouteQueryVariables
  >(AppointmentsListRouteDocument, options);
}
export type AppointmentsListRouteQueryHookResult = ReturnType<
  typeof useAppointmentsListRouteQuery
>;
export type AppointmentsListRouteLazyQueryHookResult = ReturnType<
  typeof useAppointmentsListRouteLazyQuery
>;
export type AppointmentsListRouteQueryResult = Apollo.QueryResult<
  AppointmentsListRouteQuery,
  AppointmentsListRouteQueryVariables
>;
