import {gql} from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../../../types/schema";
const defaultOptions = {};
export type AppointmentListDetailsQueryVariables = Types.Exact<{
  clinicId: Types.Scalars["ID"];
  bookingId: Types.Scalars["ID"];
}>;

export type AppointmentListDetailsQuery = {
  readonly booking: Pick<Types.Booking, "id" | "status" | "version"> & {
    readonly slot: Pick<Types.Slot, "id" | "start" | "end" | "slot_type">;
    readonly clinic: Pick<Types.Clinic, "id" | "name" | "address" | "phone">;
  };
};

export const AppointmentListDetailsDocument = gql`
  query AppointmentListDetails($clinicId: ID!, $bookingId: ID!) {
    booking(where: {clinicId: $clinicId, bookingId: $bookingId}) {
      id
      slot {
        id
        start
        end
        slot_type {
          id
          name
        }
      }
      clinic {
        id
        name
        address
        phone
      }
      status
      version
    }
  }
`;
export function useAppointmentListDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AppointmentListDetailsQuery,
    AppointmentListDetailsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    AppointmentListDetailsQuery,
    AppointmentListDetailsQueryVariables
  >(AppointmentListDetailsDocument, options);
}
export function useAppointmentListDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppointmentListDetailsQuery,
    AppointmentListDetailsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    AppointmentListDetailsQuery,
    AppointmentListDetailsQueryVariables
  >(AppointmentListDetailsDocument, options);
}
export type AppointmentListDetailsQueryHookResult = ReturnType<
  typeof useAppointmentListDetailsQuery
>;
export type AppointmentListDetailsLazyQueryHookResult = ReturnType<
  typeof useAppointmentListDetailsLazyQuery
>;
export type AppointmentListDetailsQueryResult = Apollo.QueryResult<
  AppointmentListDetailsQuery,
  AppointmentListDetailsQueryVariables
>;
