import React, {MouseEvent, useEffect, useState} from "react";
import {scrollToTop} from "../../services/scrolling";
import {classNames} from "../../utils";
import {Button} from "../forms";
import styles from "./scroll-top-button.module.scss";

export const ScrollTopButton = () => {
  const [showButton, setShowButton] = useState(false);

  const onClick = (event: MouseEvent<HTMLButtonElement>) => {
    scrollToTop();
    event.currentTarget.blur();
  };

  useEffect(() => {
    const onWindowScroll = () => {
      const offset = 50;
      const scrollY = window.scrollY;

      // Window scroll event fire very manny events, very fast, so this handler
      // needs to be very slim!
      if (!showButton && scrollY > offset) {
        setShowButton(true);
      }
      if (showButton && scrollY <= offset) {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", onWindowScroll);
    return () => {
      window.removeEventListener("scroll", onWindowScroll);
    };
  }, [showButton, setShowButton]);
  return (
    <Button
      aria-label="Till toppen av sidan"
      className={classNames(styles.root, {
        [styles.show]: showButton,
      })}
      onClick={onClick}
      tabIndex={-1}
    />
  );
};
