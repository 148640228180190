import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  PropsWithChildren,
} from "react";
import {Clinic} from "../../../types/schema";
import {SlotTypeVisibility} from "../../../utils/slot-type-visibility";
import {useSlotTypesQuery} from "./appointments-new/slotttypes.query";
import {getSlotTypeItems} from "./appointments-new/slottype-item";

// Define the type of the context value
interface SlotTypesContextValue {
  enableSelectSlotType: boolean;
  availableSlotTypes: {
    id: string;
    name: string;
    visibility: number;
  }[];
}

const SlotTypesContext = createContext<SlotTypesContextValue | undefined>(
  undefined
);
interface SlotTypesProviderProps {
  clinic: Clinic;
}

export function SlotTypesProvider({
  children,
  clinic,
}: PropsWithChildren<SlotTypesProviderProps>) {
  const [enableSelectSlotType, setEnableSelectSlotType] = useState(false);

  const clinicId = clinic?.id || "";

  const slotTypeSearchFilter = useMemo(() => {
    return createSearchSlotTypesFilter();
  }, []);
  const slotTypesVariables = {clinicId, filter: slotTypeSearchFilter};
  const slotTypesQuery = useSlotTypesQuery({variables: slotTypesVariables});
  const availableSlotTypes = getSlotTypeItems(slotTypesQuery.data);

  useEffect(() => {
    setEnableSelectSlotType(
      !!clinic?.enableSelectSlotType && availableSlotTypes.length > 0
    );
  }, [clinic, availableSlotTypes]);

  //if (loading) return <p>Loading...</p>;
  //if (error) return <p>Error: {error.message}</p>;

  return (
    <SlotTypesContext.Provider
      value={{enableSelectSlotType, availableSlotTypes}}
    >
      {children}
    </SlotTypesContext.Provider>
  );
}

export function useEnableSelectSlotType() {
  const context = useContext(SlotTypesContext);
  if (context === undefined) {
    throw new Error(
      "useEnableSelectSlotType must be used within a SlotTypesProvider"
    );
  }
  return context;
}

export function useSlotTypesContext() {
  const context = useContext(SlotTypesContext);
  if (context === undefined) {
    throw new Error(
      "useSlotTypesContext must be used within a SlotTypesProvider"
    );
  }
  return context;
}

function createSearchSlotTypesFilter() {
  return {
    conditions: [
      {
        operator: "eq",
        property: "visibility",
        value: SlotTypeVisibility.RESIDENT_PORTAL.toString(),
      },
    ],
    operator: "and",
  };
}
