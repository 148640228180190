import React, {ReactNode} from "react";
import {classNames} from "../../utils";
import styles from "./tooltip.module.scss";

export type TooltipProps = {
  children: ReactNode;
  className?: string;
  position?: "bottom" | "top";
  visible?: boolean;
};

export const Tooltip = (props: TooltipProps) => {
  const {children, className, position = "top", visible = false} = props;
  const classes = classNames(styles.root, className, {
    [styles.bottom]: position === "bottom",
    [styles.top]: position === "top",
    [styles.visible]: visible,
  });

  return <div className={classes}>{children}</div>;
};
