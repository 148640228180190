import React, {useState} from "react";
import {IDSButton, IDSIconCalendar} from "@inera/ids-react";
import {
  toLongDateString,
  toTimeString,
  Translation,
  useLocale,
} from "../../../../../services/i18n";
import {useRouting} from "../../../../../services/routing";
import {Clinic} from "../../../../../types/schema";
import styles from "../../appointments-list/appointments-list-route.module.scss";
import {useSlotTypesContext} from "../../slottypes-provider";
import {AppointmentListDetailItem} from "../appointments-list-details-item";
import {useCancelBookingMutation} from "../cancel-appointment.query";
import {CancelAppointmentDialog} from "./cancel-appointment-dialog";

type AppointmentListDetailViewProps = {
  clinic: Clinic;
  booking: AppointmentListDetailItem;
  returnUrl: string;
};

export const AppointmentsListDetailView = (
  props: AppointmentListDetailViewProps
) => {
  const {clinic, booking, returnUrl} = props;
  const routing = useRouting();
  const [showDialog, setShowDialog] = useState(false);

  const [cancelBooking] = useCancelBookingMutation();

  const isCancelEnabled = canCancel(booking);

  const onDialogCancel = () => setShowDialog(false);
  const onDialogOk = async () => {
    const clinicId = clinic.id;
    const result = await cancelBooking({
      variables: {
        clinicId: clinicId,
        id: booking.id,
        version: booking.version ? booking.version : "",
      },
    });

    setShowDialog(false);
    if (result.data?.cancelBooking) {
      routing.redirect(returnUrl);
    } else {
      // TODO: Handle failure
      console.error(result.errors);
    }
  };
  const {currentLocale} = useLocale();
  const {enableSelectSlotType} = useSlotTypesContext();
  const getClinicTypeKey = (type: string): string => {
    switch (type) {
      case "CLINICAL_PHYSIOLOGY":
        return "booked-appointments-list-details-clinphys";
      case "RADIOLOGY":
        return "booked-appointments-list-details-radiology";
      case "SAMPLE_TAKING":
        return "booked-appointments-list-details-sampletaking";
      default:
        return "";
    }
  };
  const clinicTypeKey = getClinicTypeKey(clinic.type);

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.dateSection}>
          <div className={styles.iconTextButtonRow}>
            <div className={styles.iconColumn}>
              <div className={styles.iconContainer}>
                <IDSIconCalendar size={"m"} />
              </div>
            </div>
            <div className={styles.textColumn}>
              <div className={styles.slotBody}>
                <div className={styles.slotTypeLabel}>
                  <Translation tKey="booked-appointments-list-details-slottype" />
                  {enableSelectSlotType ? (
                    booking.slot_type
                  ) : (
                    <Translation tKey={clinicTypeKey} />
                  )}
                </div>
                {toTimeString(booking?.start, currentLocale.tag)}
                {", "}
                {toLongDateString(booking?.start, currentLocale.tag)}
                <div>
                  <div className={styles.clinicName}>{clinic.name}</div>
                  <div className={styles.clinicAddress}>
                    {clinic.address &&
                      clinic.address.map((addressLine, index) => (
                        <div key={index}>{addressLine}</div>
                      ))}
                    <div>
                      <a href={`tel:${clinic.phone}`}>{clinic.phone}</a>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.cancelButton}>
                {isCancelEnabled && (
                  <IDSButton
                    size={"s"}
                    secondary={true}
                    className={styles.cancelButton}
                    onClick={() => setShowDialog(true)}
                  >
                    <Translation tKey="appointment-details-cancel-button" />
                  </IDSButton>
                )}
              </div>
            </div>
          </div>
        </div>
        <CancelAppointmentDialog
          onCancel={onDialogCancel}
          onOk={onDialogOk}
          show={showDialog}
        />
      </div>
    </div>
  );

  function canCancel(booking: AppointmentListDetailItem) {
    const startDateTime = new Date(booking.start);
    const currentDateTime = new Date();

    return startDateTime >= currentDateTime;
  }
};
