import {Route, Switch, useRouteMatch} from "react-router-dom";
import {NotFound} from "../404";
import {ClinicRoute} from "./clinic-route";

export const ClinicsRoute = () => {
  const routeMatch = useRouteMatch();

  return (
    <Switch>
      <Route path={`${routeMatch.url}/:hsaId`} component={ClinicRoute} />
      <Route component={NotFound} />
    </Switch>
  );
};
