import React, {useEffect, useState} from "react";
import {
  eachDayOfInterval,
  addDays,
  startOfDay,
  formatISO,
  Locale,
} from "date-fns";
import {format} from "date-fns";
import {sv} from "date-fns/locale";
import {DayPicker} from "react-day-picker";
import {Button} from "../../../../../components/forms";
import {
  toLongDateString,
  toTimeString,
  Translation,
  useLocale,
} from "../../../../../services/i18n";
import {toLocalIsoDateString, toLocalIsoTimeString} from "../../../../../utils";
import "react-day-picker/dist/style.css";
import styles from "../appointments-new-route.module.scss";
import {SlotItem} from "../slot-item";

type SelectSlotDatePickerProps = {
  onSlotChange: (slot?: SlotItem) => void;
  selectedSlot?: SlotItem;
  slots: SlotItem[];
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export const SelectSlotDatePicker = (props: SelectSlotDatePickerProps) => {
  const {currentLocale} = useLocale();
  const {onSlotChange, selectedSlot, slots} = props;

  useEffect(() => {
    if (slots.length > 0 && !selectedSlot) {
      onSlotChange(slots[0]);
    }
  }, [slots, selectedSlot, onSlotChange]);

  // Get an array of dates for the next 30 days
  const next30Days = eachDayOfInterval({
    start: new Date(),
    end: addDays(new Date(), 30),
  }).map(startOfDay);

  // Convert the slots array to a set for efficient lookup
  const slotsSet = new Set(
    slots.map((slot) =>
      formatISO(startOfDay(slot.start), {representation: "date"})
    )
  );

  // Get an array of dates not in the slots array
  const noSlotsDates = next30Days.filter(
    (date) => !slotsSet.has(formatISO(date, {representation: "date"}))
  );

  const firstAvailableDate = slots.reduce((earliest, slot) => {
    const startDate = new Date(slot.start);
    return startDate < earliest ? startDate : earliest;
  }, new Date(slots[0].start));

  const [selected, setSelected] = useState(
    selectedSlot ? selectedSlot.start : firstAvailableDate
  );

  if (slots.length === 0) {
    return null;
  }
  const slotsByDate = organizeSlots(slots);

  const disabledDays: Date[] = noSlotsDates;

  const toDate = new Date();
  toDate.setDate(toDate.getDate() + 30);

  function formatCaption(month: Date, options?: {locale?: Locale}) {
    return <span>{format(month, "LLLL", {locale: options?.locale})}</span>;
  }

  return (
    <div>
      <div>
        <div className={styles.pickSlot}>
          <strong>
            <Translation tKey="book-appointment-step-2-slot-pick-slot" />
          </strong>
        </div>

        <style>
          {`
           .rdp

           .rdp-dropdown {
              padding: 0.8rem;
            }

            button {
            }
			     .rdp-day{
             color: #396291;

            }
            .rdp-day_selected,
            .rdp-day_selected:focus-visible,
            .rdp-day_selected:hover {
             color: white;
             background-color: #396291;;
          }
        `}
        </style>

        <div className={styles.dayPickerBox}>
          <DayPicker
            formatters={{
              formatCaption: formatCaption,
            }}
            captionLayout="dropdown"
            mode="single"
            fromDate={new Date()}
            toDate={toDate}
            selected={selected}
            onSelect={(selectedDay) => {
              if (selectedDay !== undefined) {
                setSelected(selectedDay);
              } else {
                setSelected(new Date());
              }
            }}
            defaultMonth={selected}
            disabled={disabledDays}
            className={classNames(styles.dayPicker)}
            locale={sv}
          />
        </div>
      </div>

      <div className={styles.dateSection}>
        {slotsByDate
          .filter((slots) => {
            const slotDate = slots[0].start;

            const areDatesEqual =
              slotDate.getFullYear() === selected?.getFullYear() &&
              slotDate.getMonth() === selected?.getMonth() &&
              slotDate.getDate() === selected?.getDate();

            return areDatesEqual;
          })
          .map((slots, index) => {
            const key = `dateGroup-${index}`;
            const localDate = toLongDateString(
              slots[0].start,
              currentLocale.tag
            );

            return (
              <div key={key}>
                <div className={styles.dateHead}>
                  <div>
                    <div className={styles.slotsAvailable}>
                      <Translation tKey="book-appointment-step-2-slots-available" />
                      {localDate}
                    </div>
                  </div>
                </div>
                <div className={styles.slots}>
                  {slots.map((slot) => {
                    const localTime = toTimeString(
                      slot.start,
                      currentLocale.tag
                    );

                    return (
                      <div className={styles.slot} key={slot.id}>
                        <Button
                          onClick={() => onSlotChange(slot)}
                          primary={slot.id === selectedSlot?.id}
                        >
                          {localTime}
                        </Button>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

/**
 * Returns a sorted two dimensional array where the first is the date and the
 * second is the time-of-day.
 */
function organizeSlots<T extends {start: Date}>(slots: T[]) {
  const sorted = sortByStartDate(slots);
  const groups: T[][] = [];
  let previousIsoDate = "";
  let previousIsoTime = "";

  for (const slot of sorted) {
    const isoDate = toLocalIsoDateString(slot.start);
    const isoTime = toLocalIsoTimeString(slot.start);

    if (isoDate !== previousIsoDate) {
      groups[groups.length] = [slot];
    } else {
      if (isoTime !== previousIsoTime) {
        groups[groups.length - 1].push(slot);
      }
    }
    previousIsoDate = isoDate;
    previousIsoTime = isoTime;
  }
  return groups;
}

function sortByStartDate<T extends {start: Date}>(slots: T[]) {
  return slots.sort((a, b) =>
    a.start > b.start ? 1 : a.start < b.start ? -1 : 0
  );
}
