import {Day, Hour, Minute, Month, Weekday, Year} from "../../utils";
import {DateTimeStyleFormat} from "../../utils/date-time-style-format";

/** For now, let's use same (Swedish) date/time format everywhere */
const defaultLocale = "sv-SE";

/**
 * Formats a Date as: `"2020-02-29"`.
 * If date is undefined an empty string is returned.
 */
export const toDateString = (date?: Date, locale = defaultLocale) => {
  const options: Intl.DateTimeFormatOptions = {};

  return date?.toLocaleDateString(locale, options) || "";
};

/**
 * Formats a Date as: `"2020-02-29 14:15"`.
 * If date is undefined an empty string is returned.
 */
export const toDateTimeString = (date?: Date, locale = defaultLocale) => {
  const options: Intl.DateTimeFormatOptions = {
    year: Year.Numeric,
    month: Month.TwoDigit,
    day: Day.TwoDigit,
    hour: Hour.TwoDigit,
    minute: Minute.TwoDigit,
  };

  return date?.toLocaleString(locale, options) || "";
};

/**
 * Formats a Date as: `"lördag 29 februari 2021"`.
 * If date is undefined an empty string is returned.
 */
export const toLongDateString = (date?: Date, locale = defaultLocale) => {
  if (!date) {
    return "";
  }
  const options: Intl.DateTimeFormatOptions = {
    // @ts-ignore
    dateStyle: DateTimeStyleFormat.Full,
  };

  return new Intl.DateTimeFormat(locale, options).format(date);
};

/**
 * Formats a Date as: `"lördag 29 februari 2020 14:15"`.
 * If date is undefined an empty string is returned.
 */
export const toLongDateTimeString = (date?: Date, locale = defaultLocale) => {
  if (!date) {
    return "";
  }
  const options: Intl.DateTimeFormatOptions = {
    // @ts-ignore
    dateStyle: DateTimeStyleFormat.Full,
    timeStyle: DateTimeStyleFormat.Short,
  };

  return new Intl.DateTimeFormat(locale, options).format(date);
};

/**
 * Formats a Date as: `"14:15"`.
 * If date is undefined an empty string is returned.
 */
export const toTimeString = (date?: Date, locale = defaultLocale) => {
  const options: Intl.DateTimeFormatOptions = {
    hour: Hour.TwoDigit,
    minute: Minute.TwoDigit,
  };

  return date?.toLocaleTimeString(locale, options) || "";
};

/**
 * Returns the day, eg.: `"03"`.
 * If date is undefined an empty string is returned.
 */
export const toDay = (date?: Date, locale = defaultLocale) => {
  const options: Intl.DateTimeFormatOptions = {day: Day.TwoDigit};

  return date?.toLocaleString(locale, options) || "";
};

/**
 * Returns the short month name, eg.: `"dec."`.
 * If date is undefined an empty string is returned.
 */
export const toMonthNameShort = (date?: Date, locale = defaultLocale) => {
  const options: Intl.DateTimeFormatOptions = {month: Month.Short};

  return date?.toLocaleString(locale, options) || "";
};

export {toYearString};

/**
 * Returns the short weekday name, eg.: `"tors"`.
 * If date is undefined an empty string is returned.
 */
export const toWeekdayShort = (date?: Date, locale = defaultLocale) => {
  const options: Intl.DateTimeFormatOptions = {weekday: Weekday.Short};

  return date?.toLocaleString(locale, options) || "";
};

/**
 * Returns the year. Nothing else.
 * If date is undefined an empty string is returned.
 */
function toYearString(date?: Date, locale = defaultLocale) {
  const options: Intl.DateTimeFormatOptions = {year: Year.Numeric};

  return date?.toLocaleString(locale, options) || "";
}
