import React, {ReactNode} from "react";
import {classNames} from "../../utils";
import {Link} from "../link";
import styles from "./link-button.module.scss";

export type LinkButtonProps = {
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  primary?: boolean;
  url: string;
};

export const LinkButton = (props: LinkButtonProps) => {
  const {children, className, primary = false, url} = props;
  const classes = classNames(styles.root, className, {
    [styles.primary]: primary,
  });

  return (
    <Link className={classes} to={url}>
      {children}
    </Link>
  );
};
