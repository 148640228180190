import {ChevronLeft} from "@material-ui/icons";
import {useParams} from "react-router-dom";
import {DefaultLayout} from "../../../../components/layouts";
import {Link} from "../../../../components/link";
import {LoadingAndError} from "../../../../components/loading-and-error";
import {Translation} from "../../../../services/i18n";
import {Clinic} from "../../../../types/schema";
import styles from "../appointments-list/appointments-list-route.module.scss";
import {getAppointmentListDetailItem} from "./appointments-list-details-item";
import {useAppointmentListDetailsQuery} from "./appointments-list-details.query";
import {AppointmentsListDetailView} from "./components/appointments-list-detail-view";

type AppointmentsListDetailRouteProps = {
  clinic: Clinic;
  returnUrl: string;
};

type DetailViewRouteParams = {
  bookingId: string;
};

export const AppointmentsListDetailsRoute = (
  props: AppointmentsListDetailRouteProps
) => {
  const {clinic, returnUrl} = props;
  const {bookingId} = useParams<DetailViewRouteParams>();
  const clinicId = clinic.id;
  const variables = {clinicId, bookingId};

  const query = useAppointmentListDetailsQuery({variables});
  const booking = getAppointmentListDetailItem(query.data);

  return (
    <DefaultLayout className={styles.root}>
      <div className={styles.menuNav}>
        <Link className={styles.link} to={returnUrl}>
          <ChevronLeft className={styles.icon} />
        </Link>
        <h3>
          <Translation tKey="booked-appointments-list-details-heading" />
        </h3>
      </div>

      <LoadingAndError error={query.error} loading={query.loading}>
        {booking && (
          <AppointmentsListDetailView
            clinic={clinic}
            booking={booking}
            returnUrl={returnUrl}
          />
        )}
      </LoadingAndError>
    </DefaultLayout>
  );
};
