import React, {ButtonHTMLAttributes, DetailedHTMLProps} from "react";
import {classNames} from "../../../utils";
import styles from "./button.module.scss";

type HTMLButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

type BaseButtonProps = HTMLButtonProps & {
  primary?: boolean;
  type: "button" | "reset" | "submit";
};

const BaseButton = (props: BaseButtonProps) => {
  const {className, primary = false, type, ...otherProps} = props;
  const classes = classNames(styles.root, className, {
    [styles.primary]: primary,
  });

  return <button {...otherProps} className={classes} type={type} />;
};

export type ButtonProps = Omit<BaseButtonProps, "type">;

export const Button = (props: ButtonProps) => (
  <BaseButton {...props} type="button" />
);

export const ResetButton = (props: ButtonProps) => (
  <BaseButton {...props} type="reset" />
);

export const SubmitButton = (props: ButtonProps) => (
  <BaseButton {...props} type="submit" />
);
