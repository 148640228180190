import React from "react";
import {useTranslation} from "../../services/i18n";
import {classNames} from "../../utils";
import {clamp} from "../../utils";
import styles from "./progress-bar.module.scss";

type ProgressBarProps = {
  className?: string;
  max?: number;
  min?: number;
  /** value should be a number between 0 and 100 */
  value: number;
};

export const ProgressBar = (props: ProgressBarProps) => {
  const {className, value} = props;
  const classes = classNames(styles.root, className);
  const percent = clamp(0, value, 100);
  const width = `${Math.round(percent)}%`;
  const title = useTranslation("progress-bar-title", {value: width});

  return (
    <div className={classes} title={title}>
      <div className={styles.fill} style={{width}}></div>
    </div>
  );
};
