import {BookingStatus} from "../../../../types/schema";
import {AppointmentsListRouteQuery} from "./appointments-list-route.query";

export const getAppointmentsListItems = (data?: AppointmentsListRouteQuery) =>
  data?.searchBookings.edges
    .filter((booking) => booking.node.status !== BookingStatus.CANCELLED)
    .map(({node}) => ({
      id: node.id,
      start: new Date(node.slot.start),
      end: new Date(node.slot.end),
      name: node.clinic.name,
      address: node.clinic.address,
      phone: node.clinic.phone,
      status: node.status,
      version: node.version,
      slot_type: node.slot.slot_type.name,
    })) ?? [];

export type AppointmentsListItems = ReturnType<typeof getAppointmentsListItems>;
export type AppointmentsListItem = AppointmentsListItems[number];
