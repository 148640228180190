import React from "react";
import {ApolloError} from "@apollo/client";
import {isNonEmptyString} from "../../utils";
import styles from "./display-apollo-error.module.scss";

const isProduction = process.env.NODE_ENV === "production";

type DisplayApolloErrorProps = {
  error?: ApolloError;
};

export const DisplayApolloError = (props: DisplayApolloErrorProps) => {
  const {error} = props;

  if (!error) {
    return null;
  }
  const {graphQLErrors, networkError} = error;
  const hasGqlErrors = graphQLErrors.length > 0;

  if (isProduction) {
    return <InnerError error={error} showStack={false} />;
  }
  return (
    <div className={styles.root}>
      <InnerError error={error} />
      {hasGqlErrors &&
        graphQLErrors.map((gqlError, index) => (
          <InnerError error={gqlError} key={index} />
        ))}
      {networkError && <InnerError error={networkError} />}
    </div>
  );
};

interface InnerErrorProps {
  error?: {
    name: string;
    message: string;
    stack?: string;
  };
  showStack?: boolean;
}

function InnerError(props: InnerErrorProps) {
  const {error, showStack = true} = props;

  if (!error) {
    return null;
  }
  const {name, message, stack} = error;

  return (
    <div className={styles.error}>
      <div className={styles.name}>{name}</div>
      <div className={styles.message}>{message}</div>
      {showStack && isNonEmptyString(stack) && (
        <pre className={styles.stack}>{stack}</pre>
      )}
    </div>
  );
}
