import React, {ChangeEvent, Fragment} from "react";
import styles from "./checkbox.module.scss";

export type CheckboxProps = {
  checked?: boolean;
  name: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  value: string;
};

export const Checkbox = (props: CheckboxProps) => (
  <Fragment>
    <input {...props} className={styles.checkbox} type="checkbox" />
    <div className={styles.fakeCheckbox} />
  </Fragment>
);
