import {routeWithId, routeWithIdActionAndQuery} from "../../utils/routes";

const BASE_URL = "/";

export type AppointmentAction = "cancel" | "new" | "reschedule" | "list";
export type AppointmentQuery = {careUnitId: string};

export const getAppointmentsRoute = routeWithIdActionAndQuery<
  AppointmentAction,
  AppointmentQuery
>(BASE_URL, "appointments");

export const getCareListingsRoute = routeWithId(BASE_URL, "care-listings");

export const getHomeRoute = () => BASE_URL;

export type PrescriptionAction = "renew";
export type PrescriptionQuery = {};

export const getPrescriptionsRoute = routeWithIdActionAndQuery<
  PrescriptionAction,
  PrescriptionQuery
>(BASE_URL, "prescriptions");

export const getSettingsRoute = routeWithId(BASE_URL, "settings");
