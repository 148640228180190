import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {IDSButton, IDSButtonGroup} from "@inera/ids-react";
import {generatePath, useHistory} from "react-router-dom";
import {InfoBox} from "../../../../components/info-box";
import {DefaultLayout} from "../../../../components/layouts";
import {ProgressBar} from "../../../../components/progress-bar";
import {Spinner} from "../../../../components/spinner";
import {Translation, useTranslation} from "../../../../services/i18n";
import {scrollToTop} from "../../../../services/scrolling";
import {Clinic, Slot, SlotStatus, SlotType} from "../../../../types/schema";
import {addDays, addMinutes, classNames, isUndefined} from "../../../../utils";
import {assert} from "../../../../utils/assert";
import {toMinutes} from "../../../../utils/time/duration";
import {useSlotTypesContext} from "../slottypes-provider";
import styles from "./appointments-new-route.module.scss";
import {
  useAddBookingMutation,
  useAppointmentsNewRouteLazyQuery,
} from "./appointments-new-route.query";
import {SelectedSlot} from "./components/selected-slot";
import {SelectedSlotType} from "./components/selected-slottype";
import {SelectSlotType} from "./components/step-1-select-slottype";
import {SelectSlotDatePicker} from "./components/step-2-select-slot";
import {ReferralInformation} from "./components/step-3-referral-information";
import {SlotItem} from "./slot-item";

export {AppointmentsNewRoute};

// The `clinic.externalBookingStartRestriction` is a duration into the
// future where the user is not allowed to book an appointment. This value is
// set by the clinic. But we also need a bit of buffer for the acutal booking
// procedure. This is where the system booking buffer comes in.
const SYSTEM_BOOKING_BUFFER_IN_MINUTES = 15;

interface AppointmentsNewRouteProps {
  clinic: Clinic;
}

function AppointmentsNewRoute(props: AppointmentsNewRouteProps) {
  const {clinic} = props;
  const selectedSlotTypeRef = useRef<SlotType>();
  const [selectedSlotType, setSelectedSlotType] = useState<
    SlotType | undefined
  >(() => {
    const storedValue = localStorage.getItem("selectedSlotType");
    const parsedValue = storedValue ? JSON.parse(storedValue) : undefined;
    selectedSlotTypeRef.current = parsedValue;

    return parsedValue;
  });
  const [selectedSlot, setSelectedSlot] = useState<SlotItem>();

  const resetSelectedSlotType = () => {
    setSelectedSlotType(undefined);
    selectedSlotTypeRef.current = undefined;
    localStorage.removeItem("selectedSlotType");
  };

  const history = useHistory();

  const [availableSlots, setAvailableSlots] = useState<Slot[]>([]);

  useEffect(() => {
    if (selectedSlotType) {
      localStorage.setItem(
        "selectedSlotType",
        JSON.stringify(selectedSlotType)
      );
    } else {
      localStorage.removeItem("selectedSlotType");
    }
  }, [selectedSlotType, selectedSlotTypeRef]);

  const onSlotTypeChange = (newSlotType: SlotType) => {
    setSelectedSlotType(newSlotType);
    selectedSlotTypeRef.current = newSlotType;
  };
  const clinicId = clinic?.id || "";

  const {enableSelectSlotType, availableSlotTypes} = useSlotTypesContext();

  const filter = useMemo(() => {
    // The number of days into the future to fetch slots.
    // This value may also come from the clinic at some point.
    const daysToFetch = 30;

    const startRestrictionInMinutes =
      toMinutes(clinic.externalBookingStartRestriction) +
      SYSTEM_BOOKING_BUFFER_IN_MINUTES;

    return createSearchSlotsFilter(daysToFetch, startRestrictionInMinutes);
  }, [clinic]);

  const variables = {clinicId, filter};
  const [executeQuery, {data, loading, error}] =
    useAppointmentsNewRouteLazyQuery({variables});

  const steps = useMemo(() => {
    return [0, 1, 2, 3];
  }, []);

  const [currentStep, setCurrentStep] = useState(
    enableSelectSlotType ? steps[1] : steps[2]
  );
  const [loadingSlots, setLoadingSlots] = useState(false);

  useEffect(() => {
    if (currentStep === 2 && !enableSelectSlotType) {
      setLoadingSlots(true);
      executeQuery().then(({data}) => {
        if (data?.searchSlots.edges) {
          setAvailableSlots(
            data.searchSlots.edges.map((edge) => ({
              ...edge.node,
              clinic: {id: clinic.id, reference: ""},
              slot_type: {id: "", name: "", visibility: 0},
              appointment: null,
              resource_index: 0,
              entity_type: "",
            }))
          );
        }
      });
      setLoadingSlots(false);
    }
  }, [currentStep, enableSelectSlotType, clinic.id, executeQuery, loading]);

  const slotId = selectedSlot?.id || "";
  const [addBookingMutation, addBookingMutationResult] =
    useAddBookingMutation();

  const isLastStep = currentStep === steps.length - 1;
  const progress = (currentStep / steps.length) * 100;

  const defaultActionText = useTranslation("book-appointment-next");
  const confirmActionText = useTranslation("book-appointment-confirm");
  let nextActionText = defaultActionText;
  if (currentStep === 3) {
    nextActionText = confirmActionText;
  }
  const cancelBookingButton = useTranslation(
    "book-appointment-cancel-booking-button"
  );
  const backActionText = useTranslation("book-appointment-back");

  const onClickConfirm = () => {
    const path = generatePath("/clinics/:hsaId/appointments", {
      hsaId: clinic.hsaId,
    });
    const variables = {clinicId, slotId};

    addBookingMutation({variables});
    history.push({
      pathname: path,
      state: {showAlert: true},
    });

    resetSelectedSlotType();
  };

  const onClickBack = useCallback(() => {
    if (currentStep === 3) {
      setSelectedSlot(selectedSlot);
    }
    setCurrentStep(currentStep - 1);
    scrollToTop();
  }, [currentStep, selectedSlot]);

  const onClickCancel = useCallback(() => {
    resetSelectedSlotType();
    const path = generatePath("/clinics/:hsaId/appointments", {
      hsaId: clinic.hsaId,
    });
    history.push(path);
    scrollToTop();
  }, [history, clinic.hsaId]);

  const onClickNext = useCallback(() => {
    const goNext = () => {
      setCurrentStep(currentStep + 1);
      scrollToTop();
    };

    if (
      currentStep === 1 &&
      enableSelectSlotType &&
      selectedSlotTypeRef.current
    ) {
      const daysToFetch = 30;
      const startRestrictionInMinutes =
        toMinutes(clinic.externalBookingStartRestriction) +
        SYSTEM_BOOKING_BUFFER_IN_MINUTES;
      const filter = createSearchSlotsFilter(
        daysToFetch,
        startRestrictionInMinutes,
        selectedSlotTypeRef.current.id
      );
      const variables = {clinicId, filter};

      executeQuery({variables}).then(({data}) => {
        if (data?.searchSlots.edges) {
          setAvailableSlots(
            data.searchSlots.edges.map((edge) => ({
              ...edge.node,
              clinic: {id: clinic.id, reference: ""},
              slot_type: {id: "", name: "", visibility: 0},
              appointment: null,
              resource_index: 0,
              entity_type: "",
            }))
          );
        }
      });
    }

    if (currentStep === 3) {
      const variables = {clinicId, slotId};
      addBookingMutation({variables}).then(goNext);
    } else {
      goNext();
    }
  }, [
    addBookingMutation,
    clinicId,
    currentStep,
    slotId,
    enableSelectSlotType,
    executeQuery,
    clinic.id,
    clinic.externalBookingStartRestriction,
  ]);

  const isNextEnabled = canGoNext(currentStep, {
    slot: selectedSlot,
    selectedSlotTypeRef: selectedSlotTypeRef.current,
    enableSelectSlotType,
  });
  const isHideButtons = isLastStep;
  const hasAvailableSlots =
    (data?.searchSlots.edges?.length ?? 0) > 0 && availableSlots.length > 0;
  const hideNextButton = !enableSelectSlotType && !hasAvailableSlots;
  const hideBackButton = !canGoBack(currentStep, enableSelectSlotType);

  const renderStep = (step: number) => {
    switch (step) {
      case 1:
        return (
          <>
            <SelectSlotType
              onSlotTypeChange={onSlotTypeChange}
              selectedSlotTypeRef={selectedSlotTypeRef}
              slotTypes={availableSlotTypes}
            />
          </>
        );

      case 2:
        return (
          <>
            {enableSelectSlotType && (
              <div className={styles.bottomBorder}>
                <SelectedSlotType
                  slotType={
                    selectedSlotTypeRef.current || {
                      id: "",
                      name: "",
                      visibility: 0,
                    }
                  }
                />
              </div>
            )}
            {loadingSlots ? (
              <div className={styles.loading}>
                <Spinner />
              </div>
            ) : (
              availableSlots.length > 0 && (
                <SelectSlotDatePicker
                  onSlotChange={setSelectedSlot}
                  selectedSlot={selectedSlot}
                  slots={
                    availableSlots?.map((slot) => ({
                      id: slot.id,
                      start: new Date(slot.start),
                      end: new Date(slot.end),
                      clinic: {id: clinic.id, reference: ""},
                      slot_type: slot.slot_type.name,
                      slot_type_id: slot.slot_type_id,
                      appointment: slot.appointment,
                      resource_index: slot.resource_index,
                      version: slot.version,
                      entity_type: slot.entity_type,
                    })) || []
                  }
                />
              )
            )}
          </>
        );
      case 3:
        return (
          <>
            {enableSelectSlotType && (
              <SelectedSlotType
                slotType={
                  selectedSlotTypeRef.current || {
                    id: "",
                    name: "",
                    visibility: 0,
                  }
                }
              />
            )}
            <SelectedSlot slot={selectedSlot!} />
            <ReferralInformation clinic={clinic} />
          </>
        );
    }
  };
  return (
    <DefaultLayout className={styles.root}>
      <div className={styles.content}>
        <h2>
          <Translation tKey="book-appointment-title" />
          {clinic.name}
        </h2>
      </div>
      <div className={classNames(styles.main, {[styles.receipt]: isLastStep})}>
        {/* Slots Data */}
        {error && (
          <div className={styles.error}>
            <Translation tKey={error.message} />
          </div>
        )}
        {loading && (
          <div className={styles.loading}>
            <Spinner />
          </div>
        )}
        {currentStep === 2 && !hasAvailableSlots && !loading && (
          <>
            <div className={styles.noSlots}>
              <InfoBox>
                <p>
                  <Translation tKey="book-appointment-no-slots" />
                </p>
              </InfoBox>
            </div>
          </>
        )}

        {/* Add Booking Data */}
        {addBookingMutationResult.error && (
          <div className={styles.error}>
            <Translation tKey={addBookingMutationResult.error.message} />
          </div>
        )}

        {addBookingMutationResult.loading && (
          <div className={styles.loading}>
            <Spinner />
          </div>
        )}

        {(enableSelectSlotType || hasAvailableSlots) && renderStep(currentStep)}
      </div>

      {!hideNextButton && <ProgressBar value={progress} />}

      {isLastStep && (
        <div className={styles.buttonGroup}>
          <div>
            <IDSButton tertiary={true} onClick={onClickCancel}>
              {cancelBookingButton}
            </IDSButton>
          </div>

          <IDSButtonGroup className={styles.IdsButtonGroup}>
            <IDSButton secondary={true} onClick={onClickBack}>
              {backActionText}
            </IDSButton>
            <IDSButton onClick={onClickConfirm}>{nextActionText}</IDSButton>
          </IDSButtonGroup>
        </div>
      )}

      {!isHideButtons && (
        <div className={styles.buttonGroup}>
          {currentStep >= 0 && (
            <div>
              <IDSButton tertiary={true} onClick={onClickCancel}>
                {cancelBookingButton}
              </IDSButton>
            </div>
          )}

          <IDSButtonGroup className={styles.IdsButtonGroup}>
            {!hideBackButton && (
              <IDSButton secondary={true} onClick={onClickBack}>
                {backActionText}
              </IDSButton>
            )}
            {!hideNextButton && (
              <IDSButton
                disabled={!isNextEnabled || addBookingMutationResult.loading}
                onClick={onClickNext}
              >
                {nextActionText}
              </IDSButton>
            )}
          </IDSButtonGroup>
        </div>
      )}
    </DefaultLayout>
  );
}

interface CanGoNextData {
  slot?: SlotItem;
  selectedSlotTypeRef?: SlotType | null;
  enableSelectSlotType?: boolean;
}

function canGoNext(currentStep: number, data: CanGoNextData) {
  const {slot, selectedSlotTypeRef, enableSelectSlotType} = data;

  if (
    currentStep === 0 &&
    enableSelectSlotType &&
    isUndefined(selectedSlotTypeRef)
  ) {
    return false;
  }

  if (
    currentStep === 1 &&
    enableSelectSlotType &&
    isUndefined(selectedSlotTypeRef)
  ) {
    return false;
  }

  if (currentStep === 2 && isUndefined(slot)) {
    return false;
  }

  return true;
}

function canGoBack(currentStep: number, hasMultipleSlotTypes: boolean) {
  const MINIMUM_STEP_FOR_MULTIPLE_SLOTS = 2;

  if (hasMultipleSlotTypes) {
    return currentStep >= MINIMUM_STEP_FOR_MULTIPLE_SLOTS;
  }
  return currentStep !== MINIMUM_STEP_FOR_MULTIPLE_SLOTS;
}

function createSearchSlotsFilter(
  noOfDays = 1,
  leadTimeInMinutes = 0,
  slotTypeId?: string
) {
  assert(noOfDays > 0, "Number of days must be greater than 0");
  assert(leadTimeInMinutes >= 0, "Lead time must not be negative");

  const now = new Date();
  const fromDate = addMinutes(now, leadTimeInMinutes);
  const toDate = addDays(fromDate, noOfDays);

  const conditions = [
    {operator: "eq", property: "appointment", value: null},
    {operator: "gt", property: "start", value: fromDate.toISOString()},
    {operator: "lt", property: "start", value: toDate.toISOString()},
    {operator: "eq", property: "status", value: SlotStatus.CREATED},
  ];

  if (slotTypeId) {
    conditions.push({
      operator: "eq",
      property: "slot_type.id",
      value: slotTypeId,
    });
  }

  return {
    conditions,
    operator: "and",
  };
}
