import {ReactNode} from "react";
import {ApolloError} from "@apollo/client";
import {useTranslation} from "../../services/i18n";
import {classNames} from "../../utils";
import {ErrorBoundary} from "../error-boundary";
import {Spinner} from "../spinner";
import styles from "./loading-and-error.module.scss";

export type ErrorProp = ApolloError | Error | null;

export interface LoadingAndErrorProps {
  children: ReactNode;
  className?: string;
  error?: ErrorProp;
  loading?: boolean;
  message?: string;
}

export {LoadingAndError};

/**
 * Aims to be as transparent as possible adding a contextual error
 * notification or loading panel on top of the child content.
 */
function LoadingAndError(props: LoadingAndErrorProps) {
  const genericError = useTranslation("generic-error");
  const {children, className, error, loading, message = genericError} = props;
  const classes = classNames(className, {
    [styles.root]: error || loading,
  });

  if (error) {
    console.error(error);
  }
  return (
    <ErrorBoundary name="LoadingAndError">
      <div className={classes}>
        {children}
        {error && (
          <div className={styles.error}>
            <div className={styles.message}>
              <div>{message}</div>
            </div>
          </div>
        )}
        {loading && (
          <div className={styles.loading}>
            <Spinner className={styles.spinner} />
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
}
