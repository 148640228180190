import React, {useEffect, useMemo, useState} from "react";
import {IDSAlert} from "@inera/ids-react";
import {generatePath, useLocation, useHistory} from "react-router-dom";
import {DisplayApolloError} from "../../../../components/display-apollo-error";
import {DefaultLayout} from "../../../../components/layouts";
import {LinkButton} from "../../../../components/link-button";
import {Spinner} from "../../../../components/spinner";
import {Translation, useTranslation} from "../../../../services/i18n";
import {Clinic} from "../../../../types/schema";
import {modifyTime} from "../../../../utils";
import {AppointmentsListDetailView} from "../appointments-list-details/components/appointments-list-detail-view";
import {getAppointmentsListItems} from "./appointments-list-item";
import styles from "./appointments-list-route.module.scss";
import {useAppointmentsListRouteQuery} from "./appointments-list-route.query";

export {AppointmentsListRoute};

interface AppointmentsListRouteProps {
  clinic: Clinic;
}

function AppointmentsListRoute(props: AppointmentsListRouteProps) {
  const {clinic} = props;
  const filter = useMemo(createSearchFilter, []);
  const clinicId = clinic.id;
  const variables = {clinicId, filter};

  const query = useAppointmentsListRouteQuery({variables});
  const upcomingAppointments = getAppointmentsListItems(query.data);

  const hasUpcomingAppointments = upcomingAppointments.length > 0;
  const newBookingButton = useTranslation("book-appointment-new-booking");

  const newAppointmentUrl = generatePath("/clinics/:hsaId/appointments/new", {
    hsaId: clinic.hsaId,
  });

  const returnUrl = generatePath("/clinics/:hsaId/appointments", {
    hsaId: clinic.hsaId,
  });

  const headline = useTranslation("book-appointment-confirmation-title");

  type LocationState = {
    showAlert: boolean;
  };

  const location = useLocation<LocationState>();
  const history = useHistory();
  const [showAlert, setShowAlert] = useState(
    location.state?.showAlert || false
  );

  const handleAlertClosed = () => {
    history.replace(location.pathname, null);
    setShowAlert(false);
  };

  useEffect(() => {
    query.refetch().catch(() => {});
  }, [query]);

  return (
    <DefaultLayout className={styles.root}>
      <div>
        {showAlert && (
          <div className={styles.alert}>
            <IDSAlert
              headline={headline}
              type="success"
              dismissible={true}
              onClosed={handleAlertClosed}
            >
              <Translation tKey="book-appointment-confirmation-description" />
            </IDSAlert>
          </div>
        )}
      </div>
      <div className={styles.content}>
        <h2>
          <Translation tKey="booked-appointments-list-heading" />
          {clinic.name}
        </h2>
      </div>
      <DisplayApolloError error={query.error} />
      {query.loading && (
        <div className={styles.loading}>
          <Spinner />
        </div>
      )}
      {query.data && (
        <div className={styles.main}>
          {hasUpcomingAppointments && (
            <>
              {upcomingAppointments.map(
                ({id, start, end, name, slot_type, version}) => {
                  const booking = {
                    id,
                    start: new Date(start),
                    name,
                    slot_type,
                    version,
                    end: new Date(end),
                  };

                  return (
                    <AppointmentsListDetailView
                      clinic={clinic}
                      booking={booking}
                      returnUrl={returnUrl}
                    />
                  );
                }
              )}
            </>
          )}
          {!hasUpcomingAppointments && (
            <div className={styles.emptyList}>
              <Translation tKey="booked-appointments-list-empty" />
            </div>
          )}
          <div className={styles.buttonCenter}>
            <LinkButton url={newAppointmentUrl} primary>
              {newBookingButton}
            </LinkButton>
          </div>
        </div>
      )}
    </DefaultLayout>
  );
}

function createSearchFilter() {
  const now = new Date();
  const startingTime = modifyTime(now, {hours: 6, minutes: 0, seconds: 0});

  return {
    conditions: [
      {
        operator: "gte",
        property: "slot.start",
        value: startingTime.toISOString(),
      },
    ],
    operator: "and",
  };
}
