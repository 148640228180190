import {ApolloError} from "@apollo/client";
import {
  Route,
  Redirect,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import {DisplayApolloError} from "../../components/display-apollo-error";
import {DefaultLayout} from "../../components/layouts";
import {Spinner} from "../../components/spinner";
import {useTranslation} from "../../services/i18n";
import {AppointmentsRoute} from "./appointments/appointments-route";
import styles from "./clinic-route.module.scss";
import {useClinicQuery} from "./clinic-route.query";

interface ClinicRouteParams {
  hsaId: string;
}

export const ClinicRoute = () => {
  const {hsaId} = useParams<ClinicRouteParams>();
  const routeMatch = useRouteMatch();
  const variables = {hsaId};

  const query = useClinicQuery({variables});

  const unexpectedErrorMessage = useTranslation("clinics-unexpected-error");
  const apolloError = new ApolloError({errorMessage: unexpectedErrorMessage});

  if (query.loading) {
    return (
      <DefaultLayout className={styles.layout}>
        <Spinner />
      </DefaultLayout>
    );
  }

  if (query.error) {
    return (
      <DefaultLayout className={styles.layout}>
        <DisplayApolloError error={apolloError} />
      </DefaultLayout>
    );
  }

  if (query.data) {
    return (
      <Switch>
        <Route path={`${routeMatch.path}/appointments`}>
          <AppointmentsRoute clinic={query.data.clinic!} />
        </Route>
        <Route>
          <Redirect to={`${routeMatch.url}/appointments`} />
        </Route>
      </Switch>
    );
  }
  return null;
};
