import React from "react";
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import {generateRandomString} from "../utils";
import {InternalProviderProps} from "./internal-provider";

const httpLink = createHttpLink({uri: "/graphql"});

const headersLink = setContext((operation, context) => {
  const headers = {
    ...context.headers,
    "x-request-id": generateRandomString(),
  };

  return {headers};
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: headersLink.concat(httpLink),
  name: "web",
  version: "dev",
});

export const InternalApolloProvider = (props: InternalProviderProps) => (
  <ApolloProvider client={client}>{props.children}</ApolloProvider>
);
