import {useContext} from "react";
import {interpolate, InterpolationValues} from "../../utils";
import {I18nContext, I18nContextOutOfBoundsError} from "./i18n-context";

export const useTranslation = (
  tKey: string,
  tValues?: InterpolationValues,
  fallback?: string
) => {
  const context = useContext(I18nContext);

  if (context === null) {
    throw new I18nContextOutOfBoundsError();
  }
  return interpolate(context.source[tKey], tValues) || fallback || tKey;
};
