import React, {FunctionComponent} from "react";
import {
  Link as ReactRouterLink,
  LinkProps as ReactRouterLinkProps,
} from "react-router-dom";

export type LinkProps = ReactRouterLinkProps<null> & {};

export const Link: FunctionComponent<LinkProps> = (props) => {
  const {children, component, innerRef, replace, to, ...otherProps} = props;

  return (
    <ReactRouterLink
      {...otherProps}
      component={component}
      innerRef={innerRef}
      replace={replace}
      to={to}
    >
      {children}
    </ReactRouterLink>
  );
};
