import {gql} from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../types/schema";
const defaultOptions = {};
export type ClinicQueryVariables = Types.Exact<{
  hsaId: Types.Scalars["ID"];
}>;

export type ClinicQuery = {
  readonly clinic: Types.Maybe<
    Pick<
      Types.Clinic,
      | "entity_type"
      | "id"
      | "version"
      | "hsaId"
      | "name"
      | "type"
      | "address"
      | "phone"
      | "externalBookingStartRestriction"
      | "enableSelectSlotType"
    > & {
      readonly referralInformation: Types.Maybe<
        ReadonlyArray<Pick<Types.ReferralInformationSection, "title" | "lines">>
      >;
    }
  >;
};

export const ClinicDocument = gql`
  query Clinic($hsaId: ID!) {
    clinic(where: {hsaId: $hsaId}) {
      entity_type
      id
      version
      hsaId
      name
      type
      address
      phone
      externalBookingStartRestriction
      enableSelectSlotType
      referralInformation {
        title
        lines
      }
    }
  }
`;
export function useClinicQuery(
  baseOptions: Apollo.QueryHookOptions<ClinicQuery, ClinicQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<ClinicQuery, ClinicQueryVariables>(
    ClinicDocument,
    options
  );
}
export function useClinicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ClinicQuery, ClinicQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<ClinicQuery, ClinicQueryVariables>(
    ClinicDocument,
    options
  );
}
export type ClinicQueryHookResult = ReturnType<typeof useClinicQuery>;
export type ClinicLazyQueryHookResult = ReturnType<typeof useClinicLazyQuery>;
export type ClinicQueryResult = Apollo.QueryResult<
  ClinicQuery,
  ClinicQueryVariables
>;
