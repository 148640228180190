import React, {FunctionComponent, MouseEvent} from "react";
import {classNames} from "../../utils";
import styles from "./backdrop.module.scss";

export type BackdropClickHandler = (event: MouseEvent) => void;

export type BackdropProps = {
  className?: string;
  onClick?: BackdropClickHandler;
};

export const Backdrop: FunctionComponent<BackdropProps> = (props) => {
  const {children, className, onClick} = props;
  const classes = classNames(styles.root, className);

  return (
    <div className={classes} onClick={onClick}>
      {children}
    </div>
  );
};
