import React from "react";
import {InfoBox} from "../../../../../components/info-box";
import {Clinic} from "../../../../../types/schema";
import {isNonEmptyArray} from "../../../../../utils";
import styles from "../appointments-new-route.module.scss";

interface ReferralInformationProps {
  clinic: Clinic;
}

export const ReferralInformation = (props: ReferralInformationProps) => {
  const {clinic} = props;

  return (
    <div className={styles.content}>
      {clinic?.referralInformation?.map((section, i) => (
        <InfoBox
          key={`ReferralInformationSection-${i}`}
          headline={section.title ?? undefined}
        >
          {isNonEmptyArray(section.lines) ? (
            <ul>
              {section.lines.map((line, j) => (
                <li key={`ReferralInformationSectionLine-${j}`}>{line}</li>
              ))}
            </ul>
          ) : null}
        </InfoBox>
      ))}
    </div>
  );
};
