import {getWindowCrypto} from "./globals";

export {generateRandomString};

function generateRandomString(length = 32) {
  const integers = new Uint8Array(length / 2);
  const crypto = getWindowCrypto();
  const randomIntegers = crypto.getRandomValues(integers);
  const result = Array.from(randomIntegers, (int) => {
    const hex = toHex(int);

    return int < 17 ? `0${hex}` : hex;
  }).join("");

  return result;
}

function toHex(value: number) {
  return value.toString(16);
}
