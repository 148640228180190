import React, {HTMLAttributes} from "react";
import {classNames} from "../../utils";
import styles from "./spinner.module.scss";

export type SpinnerProps = HTMLAttributes<HTMLDivElement> & {
  /** Renders a brighter spinner that works better on colored/dark background */
  invert?: boolean;
};

export const Spinner = ({invert}: SpinnerProps) => (
  <div className={classNames(styles.root, {[styles.invert]: invert})}>
    <div className={classNames(styles.dot, styles.first)}></div>
    <div className={classNames(styles.dot, styles.second)}></div>
    <div className={classNames(styles.dot, styles.third)}></div>
  </div>
);
