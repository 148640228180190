import React from "react";
import {useRouting} from "../../../services/routing";
import {classNames} from "../../../utils";
import styles from "./primary-navigation.module.scss";

export type PrimaryNavigationProps = {
  className?: string;
};

export const PrimaryNavigation = (props: PrimaryNavigationProps) => {
  const {className} = props;
  const classRoot = classNames(styles.root, className);
  const {location} = useRouting();

  return (
    <nav className={classRoot}>
      <ol className={styles.menu}>
        {getMenuItems().map(({href, text}) => {
          const isCurrentRoute = location.pathname === href;
          const classLink = classNames(styles.link, {
            [styles.currentRoute]: isCurrentRoute,
          });

          return (
            <li className={styles.listItem} key={text}>
              <a className={classLink} href={href}>
                {text}
              </a>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

function getMenuItems() {
  return [
    {href: "https://e-tjanster.1177.se", text: "Tillbaka till 1177"},
    {href: "/auth/saml/logout", text: "Logga ut"},
  ];
}
