import React from "react";
import {ModalDialog} from "../../../../../components/modal-dialog/modal-dialog";
import {useTranslation} from "../../../../../services/i18n";

export type CancelAppointmentDialogProps = {
  onCancel: () => void;
  onOk: () => void;
  show: boolean;
};

export const CancelAppointmentDialog = (
  props: CancelAppointmentDialogProps
) => {
  const {onCancel, onOk, show} = props;
  const tTitle = useTranslation("cancel-appointment-dialog-title");
  const tMessage = useTranslation("cancel-appointment-dialog-message");
  const tCloseButton = useTranslation("cancel-appointment-dialog-close-button");
  const tOkButton = useTranslation("cancel-appointment-dialog-ok-button");

  return (
    <ModalDialog
      cancelButtonLabel={tCloseButton}
      okButtonLabel={tOkButton}
      onCancel={onCancel}
      onOk={onOk}
      show={show}
      title={tTitle}
    >
      {tMessage}
    </ModalDialog>
  );
};
