import {gql} from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../../../types/schema";
const defaultOptions = {};
export type AppointmentsNewRouteQueryVariables = Types.Exact<{
  clinicId: Types.Scalars["ID"];
  filter: Types.SearchFilter;
}>;

export type AppointmentsNewRouteQuery = {
  readonly searchSlots: {
    readonly edges: ReadonlyArray<{
      readonly node: Pick<
        Types.Slot,
        "id" | "start" | "end" | "version" | "slot_type_id"
      >;
    }>;
  };
};

export type AddBookingMutationVariables = Types.Exact<{
  clinicId: Types.Scalars["ID"];
  slotId: Types.Scalars["ID"];
}>;

export type AddBookingMutation = {
  readonly addBooking: Types.Maybe<Pick<Types.Booking, "id">>;
};

export const AppointmentsNewRouteDocument = gql`
  query AppointmentsNewRoute($clinicId: ID!, $filter: SearchFilter!) {
    searchSlots(where: {clinicId: $clinicId, filter: $filter}) {
      edges {
        node {
          id
          start
          end
          version
        }
      }
    }
  }
`;
export function useAppointmentsNewRouteQuery(
  baseOptions: Apollo.QueryHookOptions<
    AppointmentsNewRouteQuery,
    AppointmentsNewRouteQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    AppointmentsNewRouteQuery,
    AppointmentsNewRouteQueryVariables
  >(AppointmentsNewRouteDocument, options);
}
export function useAppointmentsNewRouteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppointmentsNewRouteQuery,
    AppointmentsNewRouteQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    AppointmentsNewRouteQuery,
    AppointmentsNewRouteQueryVariables
  >(AppointmentsNewRouteDocument, options);
}
export type AppointmentsNewRouteQueryHookResult = ReturnType<
  typeof useAppointmentsNewRouteQuery
>;
export type AppointmentsNewRouteLazyQueryHookResult = ReturnType<
  typeof useAppointmentsNewRouteLazyQuery
>;
export type AppointmentsNewRouteQueryResult = Apollo.QueryResult<
  AppointmentsNewRouteQuery,
  AppointmentsNewRouteQueryVariables
>;
export const AddBookingDocument = gql`
  mutation AddBooking($clinicId: ID!, $slotId: ID!) {
    addBooking(where: {clinicId: $clinicId, slotId: $slotId}) {
      id
    }
  }
`;
export type AddBookingMutationFn = Apollo.MutationFunction<
  AddBookingMutation,
  AddBookingMutationVariables
>;
export function useAddBookingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddBookingMutation,
    AddBookingMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<AddBookingMutation, AddBookingMutationVariables>(
    AddBookingDocument,
    options
  );
}
export type AddBookingMutationHookResult = ReturnType<
  typeof useAddBookingMutation
>;
export type AddBookingMutationResult =
  Apollo.MutationResult<AddBookingMutation>;
export type AddBookingMutationOptions = Apollo.BaseMutationOptions<
  AddBookingMutation,
  AddBookingMutationVariables
>;
