import {AppointmentListDetailsQuery} from "./appointments-list-details.query";

export const getAppointmentListDetailItem = (
  data?: AppointmentListDetailsQuery
) => {
  if (data) {
    const booking = {
      id: data.booking.id,
      start: new Date(data.booking.slot.start),
      end: new Date(data.booking.slot.end),
      name: data.booking.clinic.name,
      version: data.booking.version,
      slot_type: data.booking.slot.slot_type.name,
    };
    return booking;
  }
};

export type AppointmentListDetailItem = NonNullable<
  ReturnType<typeof getAppointmentListDetailItem>
>;
