import React from "react";
import {ErrorBoundary} from "../components/error-boundary";
import {
  InternalApolloProvider,
  InternalI18nProvider,
  ModalProvider,
} from "../providers";
import {Router} from "./router";

import "../styles/index.scss";

export const App = () => (
  <ErrorBoundary name="AppRoot">
    <InternalApolloProvider>
      <InternalI18nProvider>
        <ModalProvider>
          <Router />
        </ModalProvider>
      </InternalI18nProvider>
    </InternalApolloProvider>
  </ErrorBoundary>
);
