import {SlotTypesQuery} from "./slotttypes.query";

export const getSlotTypeItems = (data?: SlotTypesQuery) =>
  data?.searchSlotTypes.edges.map(({node}) => ({
    id: node.id,
    name: node.name,
    visibility: node.visibility,
  })) ?? [];

export type SlotTypeItems = ReturnType<typeof getSlotTypeItems>;
export type SlotTypeItem = SlotTypeItems[number];
