import {FunctionComponent, useContext} from "react";
import {createPortal} from "react-dom";
import {ModalContext, ModalContextOutOfBoundsError} from "./modal-context";

export const Modal: FunctionComponent = ({children}) => {
  const context = useContext(ModalContext);

  if (context === null) {
    throw new ModalContextOutOfBoundsError();
  }
  const rootElement = context.rootRef.current;

  return rootElement ? createPortal(children, rootElement) : null;
};
