/**
 * NOTE!! JavaScript Date has some quirks. These tests used to do
 * `new Date('2020-01-01')` but it turns out new Date(dateString) is treated as
 * UTC time, not local!! So now we do toDate(dateString)
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date#Timestamp_string
 */

export {toLocalIsoDateString, toLocalIsoTimeString};

/**
 * Takes a Date and returns the local date (NOT UTC!) in short ISO date format.
 * YYYY-MM-DD
 */
function toLocalIsoDateString(date: Date) {
  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
    date.getDate()
  )}`;
}

/**
 * Takes a Date and returns the local time (NOT UTC!) in short ISO time format.
 * hh:mm:ss (24-hour clock)
 */
function toLocalIsoTimeString(date: Date) {
  return `${pad(date.getHours())}-${pad(date.getMinutes())}-${pad(
    date.getSeconds()
  )}`;
}

function pad(n: number) {
  return String(n).padStart(2, "0");
}
