import {
  Duration,
  parse as toDurationObject,
  serialize as toDurationString,
} from "tinyduration";
import {isString} from "../is-string";
import * as DURATION_UNIT from "./duration-unit";
import * as TIME_UNIT_QUANTITY from "./time-unit-quantity";
import {DurationUnit} from "./types";

export {
  toDurationObject,
  toDurationString,
  toMilliseconds,
  toSeconds,
  toMinutes,
};

function toMinutes(value: Duration | string) {
  const durationInMilliseconds = getDurationInMilliseconds(value);

  return durationInMilliseconds / TIME_UNIT_QUANTITY.MINUTE;
}

function toSeconds(value: Duration | string) {
  const durationInMilliseconds = getDurationInMilliseconds(value);

  return durationInMilliseconds / TIME_UNIT_QUANTITY.SECOND;
}

function toMilliseconds(value: Duration | string) {
  return getDurationInMilliseconds(value);
}

function getDurationInMilliseconds(duration: Duration | string): number {
  const {negative, ...durationValues} = isString(duration)
    ? toDurationObject(duration)
    : duration;

  const durationInMilliseconds = Object.entries(durationValues).reduce(
    (accumulator, durationValue) => {
      // ["years", 0]
      const [unit, quantity] = durationValue;

      return (
        accumulator +
        getDurationQuantityInMillisecondsByUnit(quantity, unit as DurationUnit)
      );
    },
    0
  );

  return negative ? -durationInMilliseconds : durationInMilliseconds;
}

function getDurationQuantityInMillisecondsByUnit(
  value: number,
  unit: DurationUnit
) {
  switch (unit) {
    case DURATION_UNIT.MILLISECONDS:
      return value;
    case DURATION_UNIT.SECONDS:
      return value * TIME_UNIT_QUANTITY.SECOND;
    case DURATION_UNIT.MINUTES:
      return value * TIME_UNIT_QUANTITY.MINUTE;
    case DURATION_UNIT.HOURS:
      return value * TIME_UNIT_QUANTITY.HOUR;
    default:
      console.warn(`Unsupported duration unit '${unit}'`);
      return 0;
  }
}
