import React, {ReactElement, ReactNode} from "react";
import {IDSIconInformation} from "@inera/ids-react";
import {classNames} from "../../utils";
import styles from "./info-box.module.scss";

type InfoBoxProps = {
  children: ReactNode;
  className?: string;
  headline?: string;
  icon?: ReactElement;
  variant?: "information" | "observe";
};

export const InfoBox = (props: InfoBoxProps) => {
  const {children, className, headline, icon, variant = "information"} = props;

  return (
    <div
      className={classNames(styles.root, className, {
        [styles.isInformation]: variant === "information",
        [styles.isObserve]: variant === "observe",
      })}
    >
      {(headline || icon) && (
        <div className={styles.infoBox}>
          <div className={styles.iconContainer}>
            <IDSIconInformation size={"s"} />
          </div>
          <div className={styles.headlineContainer}>
            <h3 className={styles.headline}>{headline}</h3>
          </div>
        </div>
      )}
      <div className={styles.body}>{children}</div>
    </div>
  );
};
