import React, {ChangeEvent, Fragment} from "react";
import styles from "./radio-button.module.scss";

export type RadioButtonProps = {
  checked?: boolean;
  name: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  value: string;
};

export const RadioButton = (props: RadioButtonProps) => (
  <Fragment>
    <input {...props} className={styles.radio} type="radio" />
    <div className={styles.fakeRadio} />
  </Fragment>
);
