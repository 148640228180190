import {createContext, RefObject} from "react";

export type ModalState = {
  rootRef: RefObject<HTMLDivElement>;
};

export const ModalContext = createContext<ModalState | null>(null);

export class ModalContextOutOfBoundsError extends Error {
  constructor() {
    super("You are trying to use <Modal> outside the <ModalProvider>");
  }
}
