import React from "react";
import {I18nProvider, I18nSource} from "../services/i18n";
import {Locale} from "../services/i18n/locale";
import {enGB, svSE} from "../services/i18n/locales";
import {InternalProviderProps} from "./internal-provider";

const availableLocales = [enGB, svSE];
const defaultLocale = svSE;
const fallbackLocale = svSE;

const resolver = async (locale: Locale): Promise<I18nSource> =>
  fetch(`/translations/${locale.languageCode}.json`).then((response) =>
    response.json()
  );

const config = {
  availableLocales,
  defaultLocale,
  fallbackLocale,
  resolver,
};

export const InternalI18nProvider = (props: InternalProviderProps) => (
  <I18nProvider config={config}>{props.children}</I18nProvider>
);
