import React, {useState} from "react";
import {classNames} from "../../utils";
import {Button} from "../forms";
import {Close, Hamburger} from "../icons";
import {CurrentUser} from "./current-user";
import styles from "./header.module.scss";
import {Logo} from "./logo";
import {PrimaryNavigation} from "./primary-navigation";
import {useSessionQuery} from "./session";

export type HeaderProps = {
  className?: string;
};

export const Header = (props: HeaderProps) => {
  const sessionQuery = useSessionQuery();
  const [isMenuVisible, setMenuVisible] = useState(false);
  const {className} = props;
  const classes = classNames(styles.root, className, {
    [styles.hidden]: !isMenuVisible,
  });

  const session = sessionQuery.data;

  return (
    <div className={classes}>
      <div className={styles.logos}>
        <div className={styles.logo1177}>
          <Logo name="1177" />
        </div>
        <div className={styles.logoSkane}>
          <Logo name="Skane" />
        </div>
      </div>
      {session ? (
        <CurrentUser className={styles.currentUser} session={session} />
      ) : (
        <div />
      )}
      <Button
        className={styles.menuButton}
        onClick={() => setMenuVisible(!isMenuVisible)}
      >
        {isMenuVisible ? <Close /> : <Hamburger />}
      </Button>
      <PrimaryNavigation className={styles.navigation} />
    </div>
  );
};
