import React, {Fragment} from "react";
import {classNames} from "../../utils";
import {H2} from "../headings";
import styles from "./footer.module.scss";
import {ScrollTopButton} from "./scroll-top-button";

export type FooterProps = {
  className?: string;
};

export const Footer = (props: FooterProps) => {
  const {className} = props;

  return (
    <Fragment>
      <ScrollTopButton />
      <div className={classNames(styles.root, className)}>
        <H2 inspiring layout>
          1177 Vårdguiden - <em>tryggt om din hälsa och vård</em>
        </H2>
        <p>
          Läs om hälsa och sjukdomar och var du kan hitta vård. Logga in för att
          läsa din journal och göra dina vårdärenden. Ring telefonnummer 1177
          för sjukvårdsrådgivning dygnet runt.{" "}
        </p>
        <p>
          1177 Vårdguiden är en tjänst från Sveriges regioner. Vi finns alltid
          med dig när du vill må bättre.
        </p>
      </div>
    </Fragment>
  );
};
