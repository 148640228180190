import React from "react";
import {classNames} from "../../../utils";
import {ReactComponent as Svg1177} from "./1177.svg";
import styles from "./logo.module.scss";
import {ReactComponent as SvgSkane} from "./skane.svg";

export type LogoName = "1177" | "Skane";

export type LogoProps = {
  className?: string;
  name: LogoName;
};

export const Logo = ({className, name}: LogoProps) => {
  const classes = classNames(styles.root, className, {
    [styles.logo1177]: name === "1177",
    [styles.logoSkane]: name === "Skane",
  });

  switch (name) {
    case "1177":
      return <Svg1177 className={classes} />;
    case "Skane":
      return <SvgSkane className={classes} />;
  }
};
