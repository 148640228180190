import {scrollTo as windowScrollTo} from "../utils";

export const scrollTo = (
  left = 0,
  top = 0,
  behavior: ScrollBehavior = "smooth"
) => {
  windowScrollTo({left, top, behavior});
};

export const scrollToTop = () => scrollTo(0, 0);
