import React, {ChangeEvent} from "react";
import {classNames} from "../../../utils";
import styles from "./input.module.scss";

export type HTMLInputElementType = "number" | "text";

export type InputProps = {
  autoFocus?: boolean;
  className?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  pattern?: string;
  placeholder?: string;
  type?: HTMLInputElementType;
  value?: string;
};
export const Input = (props: InputProps) => {
  const {className, type = "text", ...otherProps} = props;
  const classes = classNames(styles.root, className, styles[type]);

  return <input {...otherProps} className={classes} type={type} />;
};

/**
 * Input Text
 * */
export type InputTextProps = InputProps;
export const InputText = (props: InputTextProps) => (
  <Input {...props} type="text" />
);

/**
 * Input Number
 */
export type InputNumberProps = InputProps & {
  max?: number;
  min?: number;
  step?: number;
};
export const InputNumber = (props: InputNumberProps) => (
  <Input {...props} type="number" />
);
