import React, {FunctionComponent} from "react";
import {BackdropClickHandler, Backdrop} from "../backdrop";
import {Modal} from "../modal";
import {Dialog} from "./dialog";

type ModalDialogProps = {
  onBackdropClick?: BackdropClickHandler;
  cancelButtonLabel?: string;
  className?: string;
  okButtonLabel?: string;
  onCancel: () => void;
  onOk: () => void;
  show?: boolean;
  title?: string;
};

export const ModalDialog: FunctionComponent<ModalDialogProps> = (props) => {
  const {onBackdropClick, show = false, ...otherProps} = props;

  return (
    <Modal>
      {show && (
        <Backdrop onClick={onBackdropClick}>
          <Dialog {...otherProps} show={show} />
        </Backdrop>
      )}
    </Modal>
  );
};
