import {Route, generatePath, Switch, useRouteMatch} from "react-router-dom";
import {Clinic} from "../../../types/schema";
import {NotFound} from "../../404";
import {AppointmentsListDetailsRoute} from "./appointments-list-details/appointments-list-details-route";
import {AppointmentsListRoute} from "./appointments-list/appointments-list-route";
import {AppointmentsNewRoute} from "./appointments-new";
import {SlotTypesProvider} from "./slottypes-provider";

type AppointmentsRouteProps = {
  clinic: Clinic;
};

export const AppointmentsRoute = (props: AppointmentsRouteProps) => {
  const {clinic} = props;
  const routeMatch = useRouteMatch();

  return (
    <Switch>
      <Route path={`${routeMatch.path}/new`}>
        <SlotTypesProvider clinic={clinic}>
          <AppointmentsNewRoute clinic={clinic} />
        </SlotTypesProvider>
      </Route>
      <Route exact path={`${routeMatch.path}/:bookingId`}>
        <SlotTypesProvider clinic={clinic}>
          <AppointmentsListDetailsRoute
            clinic={clinic}
            returnUrl={generatePath("/clinics/:hsaId/appointments", {
              hsaId: clinic.hsaId,
            })}
          />
        </SlotTypesProvider>
      </Route>
      <Route exact path={routeMatch.path}>
        <SlotTypesProvider clinic={clinic}>
          <AppointmentsListRoute clinic={clinic} />
        </SlotTypesProvider>
      </Route>
      <Route component={NotFound} />
    </Switch>
  );
};
