import React from "react";
import {classNames} from "../../../utils";
import {Session} from "../session";
import styles from "./current-user.module.scss";

export type CurrentUserProps = {
  className?: string;
  session: Session;
};

export const CurrentUser = (props: CurrentUserProps) => {
  const {className, session} = props;
  const classes = classNames(styles.root, className);

  return (
    <div className={classes}>
      <div className={styles.icon} />
      <div className={styles.name}>{session.name}</div>
      <div className={styles.links}></div>
    </div>
  );
};
